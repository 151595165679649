import "./Faq.scss";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import {useEffect, useState,useRef} from 'react';
import Accordion from '@mui/material/Accordion';

import { styled } from '@mui/material/styles';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from '@mui/material/Link';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export default function Faq() {
  var scrollTop = 0;
  const [maxScrollTop, setMaxScrollTop] = useState(101);

  const reveal1 = useRef(null);

  useEffect(() => {
    const handleScroll = (event:any) => {
      scrollTop = Math.max(window.scrollY,scrollTop);
      setMaxScrollTop(scrollTop);
    };
    window.addEventListener('scroll', handleScroll);
    setMaxScrollTop(100);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getRectTop = (ref:any)=>{
    return ref?.current?.getBoundingClientRect().top  + window.scrollY -  document.documentElement.clientHeight + 100 };

  const  openLink =(link:string)=>{
    window.open(link,'_blank');
  }



  
  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      
      {...props}
    />
  ))(({ theme }) => ({
    
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(242, 232, 223, 0.3)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary.Mui-expanded': {
      marginLeft: theme.spacing(1),
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(153, 132, 125, 0.5)',
  }));

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return ( 
    
    <div className="fe-container-padtop">


         <section id="section-renata" className=" fe-section fe-section-renata">
      <div className="fe-top-renata "></div>
      <div className="fe-container-fluid">
    <Grid container spacing={2}  >
        <Grid item md={12} xs={12} sx={{display:'inline-block',pr:{md:'100px',xs:'0px'}}}>
          <Box sx={{p:{md:'0px',xs:'20px'}}}>
          <Typography  ref={reveal1} className={(maxScrollTop > getRectTop(reveal1))?"fe-r-slide reveal":"fe-r-slide " } sx={{fontStyle:'italic',mb:'1.5em'}} variant="h4" color="#98857E" >
          <Box sx={{mr:'10px',float:'left'}}>
            <img  src='/media/comma.png'></img>
          </Box>
           Questions Fréquentes
          </Typography>
          
          </Box>
        </Grid>
        <Grid item xs={12} mb="8em">
        <Typography variant="h6" mb={2} color="#98857E">
          RENATA FRANÇA
          </Typography>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
            expandIcon={expanded === 'panel1'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography >
          La <span style={{fontWeight:'bold'}}> grossesse </span> est elle une contre indication au drainage lymphatique Renata França? 
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Vous êtes nombreuses à penser qu’il n’est pas possible d’effectuer le drainage durant la grossesse… bonne nouvelle, c’est faux ! 
          <br/>D’ailleurs, il est très important de se faire drainer pendant la grossesse afin d’éviter les oedèmes, les jambes lourdes 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
             expandIcon={expanded === 'panel2'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography >
          Quelles sont les <span style={{fontWeight:'bold'}}> contres indications </span>de ce drainage?
            </Typography>
          
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          phlébite en cours, ainsi que tout signe inflammatoire viral ou bactérien.  
          <br/>Pour tout autre doute, une ordonnance de votre médecin pourrait vous être demandée.  
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
             expandIcon={expanded === 'panel3'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography>
          Les <span style={{fontWeight:'bold'}}> hommes </span> peuvent ils être drainés ? 
          </Typography>
        
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          La réponse est OUI. Les hommes ont tout autant besoin de drainer leur corps, le détoxifier et renforcer leur système immunitaire que les femmes. Seules les attentes changent. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
           expandIcon={expanded === 'panel4'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography >
          <span style={{fontWeight:'bold'}}>  Combien de séances </span> de drainage lymphatique méthode Renata França faut-il réaliser ?  
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Vous êtes nombreux à me demander combien de séances faut il prévoir, à quelle fréquence et quels sont les résultats. 
          <br/>Une fois par semaine/ par mois/ en cure ? 
          <br/>Cela dépend de vos objectifs et de votre corps. 
          <br/>Chaque corps est différent, il n’y a donc pas de réponse tout faite.  
          <br/>Un organisme lent aura besoin de plus de séances qu'un organisme rapide, même s'il y a le même objectif !  
          <br/>Prévoyez donc un peu plus de temps lors de la première séance pour votre bilan personnalisé, nous sommes là pour vous accompagner et vous comprendre afin de déterminer ensemble vos objectifs. 
          
          
          </Typography>
          <Typography>

          Une  <span style={{fontWeight:'bold'}}>  séance ponctuelle</span>  reste <span style={{fontWeight:'bold'}}>utile</span>.  
          <ul>
            <li>Elle permettra de détoxifier votre organisme, relancer votre système lymphatique et améliorer votre système immunitaire. </li>
            <li>Importante :</li>
            <li>aux changements de saison  </li>
            <li>en préparation ou récupération musculaire  </li>
            <li>lors d'un rééquilibrage alimentaire </li>
            <li>Pour vous soulager et vous aider dans des … régulières : jambes lourdes, ballonnements, transit perturbé, constipation, endométriose, ménopause</li>
          </ul>
         
          </Typography>
          <Typography>
          En  <span style={{fontWeight:'bold'}}> cure de 3 à 6 séances</span> : afin de traiter des problématiques importantes de rétention d'eau, affiner la silhouette, atténuer la cellulite. 
        
          <ul>
            <li>Lorsque vous souhaitez atteindre plus rapidement vos objectifs </li>
            <li>Pendant et après une grossesse</li>
            <li>Pour drainer votre corps de façon régulière et soulager vos maux. </li>
          </ul>
          </Typography>
        </AccordionDetails>

      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
             expandIcon={expanded === 'panel5'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography >
          Le drainage lymphatique Renata França permet-il de  <span style={{fontWeight:'bold'}}> maigrir </span>  ? 
          </Typography>
        
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Le drainage lymphatique méthode Renata França ne permet pas d’éliminer les graisses mais il va permettre d’affiner votre silhouette car il évacue l’eau et les toxines. 
          <br/>Cette « Détox » de votre corps va regalber les jambes, les cuisses, les fesses et les hanches en résorbant les œdèmes.  
          <br/>Ce massage est le complément idéal à un rééquilibrage alimentaire accompagné d’une activité physique régulière. 
          </Typography> 
          <Typography>
          Pour une  <span style={{fontWeight:'bold'}}> silhouette transformée  </span>, après quelques séances de drainage, nous vous recommandons le  <span style={{fontWeight:'bold'}}> remodelage  </span> méthode Renata França ! 
          <br/>Ce massage remodelant permet de mobiliser les  adipocytes, c’est-à-dire déplacer la graisse au bon endroit et ainsi donner plus de contour au corps. Le malaxage, le pincement et le glissement sont quelques-unes des manœuvres qui promettent de donner de nouvelles formes au corps et garantir une silhouette plus sinueuse. (Lien vers remodelage du site Spa renata França (https://sparenatafranca.com/fr/traitements/modeladora)  

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
             expandIcon={expanded === 'panel6'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography >
          Pendant <span style={{fontWeight:'bold'}}>combien de temps  </span>vont durer les effets du drainage lymphatique méthode Renata França ? 
          </Typography>
        
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          L’effet de la séance dure 3 à 7 jours.  
          <br/>Mais il faudra jouer le jeu : boire beaucoup d’eau, éviter l’alcool pendant 3 jours, avoir une bonne hygiène de de vie et pratiquer une activité physique.  
          <br/>Pour que les effets se prolongent  un rendez-vous par semaine est préconisé pendant 5 semaines. Puis, vous pourrez espacer les rendez-vous tous les mois. Cela dépendra de chaque personne.  
          <br/>Il s’agit d’un entretien, tout comme l’équilibre alimentaire et le sport, si vous stoppez complètement, aucun effet ne sera durable.  

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
        <AccordionSummary
             expandIcon={expanded === 'panel11'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel11bh-content"
          id="panel11bh-header"
        >
          <Typography >
          Comment se <span style={{fontWeight:'bold'}}>déroule la séance   </span>de drainage lymphatique méthode Renata França ? 
          </Typography>
        
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Vous serez installé sur une table de massage, en sous vêtements, avec des serviettes pour couvrir les zones non drainées. Seront exercées des pressions douces et fortes de manière alternative avec les doigts, les pouces et les paumes de la main dans le but de réactiver les lymphes. Le drainage aura un rôle de pompe pour la lymphe, comme le cœur avec les vaisseaux sanguins. 
          <br/>Le drainage lymphatique suivra le sens de circulation du réseau lymphatique et va ainsi permettre d’acheminer les toxines et les liquides stockés jusqu’au ganglions.  
          <br/>Ces ganglions procèderont à l’élimination de cette surcharge.  
          <br/>Afin de faciliter cette détoxification il est conseillé de boire 2l d’eau dans les 24 qui suivent un drainage lymphatique méthode Renata França. 
          e alimentaire et le sport, si vous stoppez complètement, aucun effet ne sera durable.  

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Typography variant="h6" my={2} color="#98857E">
          LPG
          </Typography>
        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary
            expandIcon={expanded === 'panel7'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography >
          Qu'est-ce que la <span style={{fontWeight:'bold'}}>cellulite</span> ? Qui est touché par ce phénomène ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          La cellulite provient, entre autre, d'amas graisseux emprisonnés dans les <Link href="#" onClick={()=>{openLink('https://www.passeportsante.net/fr/parties-corps/Fiche.aspx?doc=adipocytes-savoir-sur-cellules')}}> Adipocytes</Link> et d'une rétention d'eau tout autour (derme et hypoderme, liens?).  
          <br/>Résultat ?« un effet peau d'orange » ou « capitons ».  
          <br/>Rassurez vous, seul un petit pourcentage de femmes échappent à ce phénomène

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary
            expandIcon={expanded === 'panel8'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <Typography >
          Pourquoi les hommes ne sont-ils pas touchés par cet effet peau d’orange ? 
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>

          Le corps féminin a été conçu pour porter la vie et allaiter. De ce fait, il va stocker plus les lipides. La peau d’une femme est beaucoup plus fine que celle d’un homme. 
          <br/>Les hormones féminines (<span style={{fontWeight:'bold'}}>œstrogènes</span>) jouent un rôle crucial dans la formation des capitons en aggravant les <span style={{fontWeight:'bold'}}> troubles circulatoires</span>. Les changements hormonaux (puberté, grossesse,cycle menstruel, ménopause, etc) favorisent la <span style={{fontWeight:'bold'}}> rétention d'eau </span> et l'apparition de cellulite. Quand le niveau des oestrogènes change, l'organisme réagit en retenant les fluides, le tissu conjonctif et la peau se durcissent, la circulation se ralentit et le stockage des cellules graisseuses est favorisé. 
          <br/>Les cellules graisseuses, chez l'homme, sont collées, la circulation entre ces lobules est plus fluide. 
          <br/>Un tissu adipeux se trouve entre la peau et les muscles. Il est traversé par des cloisons formées de tissu conjonctif riche en collagène. Pour l’homme, les cloisons sont obliques par rapport à la peau et fixent solidement le tissu adipeux qui ne peut pas faire les fameuses vagues de « la peau d’orange », tandis que chez la femme, elles sont perpendiculaires à la peau, d’où la progression de cette cellulite disgracieuse. 

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary
            expandIcon={expanded === 'panel9'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
        >
          <Typography >
          Qu'est-ce que <span style={{fontWeight:'bold'}}>l'Endermologie®</span> ? 
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>

          L'Endermologie® est une technique issue de la recherche LPG Systems, aux résultats scientifiquement prouvés. Notre appareil Cellu M6 bénéficie de ce « label ». C’est la technique de référence 100% naturelle, non invasive et non agressive de stimulation mécanique de la peau, permettant de réactiver le mécanisme des cellules. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary
            expandIcon={expanded === 'panel10'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel10bh-content"
          id="panel10bh-header"
        >
          <Typography >
          Comment l’appareil fonctionne t’il sur le corps? 
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          La peau est mécanisée par le biais de têtes de traitement équipées de rouleaux motorisés indépendants couplés à une chambre d'aspiration. La technique du palper-rouler motorisé, exclusive à LPG Systems, stimule en douceur les tissus et relance la circulation sanguine et lymphatique. Les déchets sont éliminés et la production de collagène et d'élastine relancée. La surface de la peau est plus lisse et l'aspect peau d'orange est estompé, voire, éliminé. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
        <AccordionSummary
            expandIcon={expanded === 'panel12'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel12bh-content"
          id="panel12bh-header"
        >
          <Typography >
          Est-ce que l'Endermologie® a une réelle  <span style={{fontWeight:'bold'}}>efficacité</span> ? 
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Les études scientifiques et cliniques qui ont été menées à travers le monde par les plus prestigieuses équipes. Les appareils "d'endermologie®" de la firme LPG sont reconnus comme dispositif médical, notamment aux Etats-Unis, en Australie, en France... Pour plus d'information sur les études, nous vous invitons à visiter le site consacré à la recherche LPG Systems : <Link href="#" onClick={()=>{openLink('https://www.cosire-lpg.com')}}> www.cosire-lpg.com <OpenInNewIcon sx={{fontSize:'18px'}}></OpenInNewIcon></Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
        <AccordionSummary
            expandIcon={expanded === 'panel13'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel13bh-content"
          id="panel13bh-header"
        >
          <Typography >
          Combien de <span style={{fontWeight:'bold'}}>temps dure une séance </span> d'Endermologie® ? 
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Une séance classique dure 20 à 40 minutes. Le temps va dépendre de la/des zone(s) à traiter 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
        <AccordionSummary
            expandIcon={expanded === 'panel14'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel14bh-content"
          id="panel14bh-header"
        >
          <Typography >
          <span style={{fontWeight:'bold'}}>Combien de séances </span>  dois-je faire pour obtenir des résultats ? 
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Les résultats varient en fonction des personnes, cependant la plupart des individus constatent un changement notable de leur silhouette à partir de la sixième ou huitième séance.
          <br/>Une série de 10 à 15 séances, vous permettra d'obtenir de bons résultats. Nous vous conseillons de respecter une certaine fréquence : deux séances par semaine, puis une par semaine pour la fin de la cure. Une fois l’objectif atteint, une séance par mois suffisante pour la plupart des personnes traitées. 
          <br/>De même que vous allez régulièrement chez le coiffeur prenez soin de votre corps en effectuant de temps à autre (une fois par mois par exemple), une séance d'entretien. Sinon, la cellulite reviendra. 

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
        <AccordionSummary
            expandIcon={expanded === 'panel15'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel15bh-content"
          id="panel15bh-header"
        >
          <Typography >
           Est-ce <span style={{fontWeight:'bold'}}>douloureux</span>  ? 
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Contrairement aux idées reçues un soin d'Endermologie® ne doit pas être douloureux ! C'est un traitement relaxant, non-invasif et non-médicamenteux. Il est efficace, tout en préservant la peau et les tissus. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
        <AccordionSummary
            expandIcon={expanded === 'panel16'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel16bh-content"
          id="panel16bh-header"
        >
          <Typography >
          Quelle  <span style={{fontWeight:'bold'}}>tenue</span>  porter durant le traitement ?    ? 
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Une combinaison personnelle vous sera fournie (offerte lors d’une cure). L’endermowear se porte à chaque séance. Lavable, il préserve votre pudeur et garantit une bonne hygiène. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
        <AccordionSummary
            expandIcon={expanded === 'panel17'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel17bh-content"
          id="panel17bh-header"
        >
          <Typography >
          Peut-il y avoir des  <span style={{fontWeight:'bold'}}>effets secondaires</span> ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Les seuls effets secondaires connus sont des ecchymoses ou des rougeurs, visibles sur des peaux très sensibles ou réactives.  
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel18'} onChange={handleChange('panel18')}>
        <AccordionSummary
            expandIcon={expanded === 'panel18'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel18bh-content"
          id="panel18bh-header"
        >
          <Typography >
          S'agit-il d'un programme d'amaigrissement ? 
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Non. Si vous voulez perdre du poids, vous devez faire de l'exercice et suivre un régime amaigrissant. En revanche, vous obtiendrez de meilleurs résultats en y associant un traitement d'Endermologie. Rapidement, vous vous sentirez mieux dans vos vêtements et vous perdrez quelques centimètres aux endroits les plus stratégiques. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel19'} onChange={handleChange('panel19')}>
        <AccordionSummary
            expandIcon={expanded === 'panel19'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel19bh-content"
          id="panel19bh-header"
        >
          <Typography >
          Est-ce que le traitement agit également sur les  <span style={{fontWeight:'bold'}}>hommes</span>   ? 
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Oui, même si les hommes n'ont pas de cellulite, ils souffrent parfois de relâchement cutané (visage, ventre, pectoraux...) et les graisses peuvent être plus profondes ou plus localisées, comme dans le cas des poignées d'amour. Les essais cliniques ont démontré d'excellents résultats dans ce dernier cas ainsi que sur la peau relâchée. De plus, l'Endermologie tonifie les tissus et les muscles, ce qui en fait un excellent partenaire pour lutter contre le relâchement cutané consécutif à un régime amaigrissant. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel20'} onChange={handleChange('panel20')}>
        <AccordionSummary
            expandIcon={expanded === 'panel20'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel20bh-content"
          id="panel20bh-header"
        >
          <Typography >
          La présence de <span style={{fontWeight:'bold'}}>varices</span> est-elle une contre indication   ? 
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Si les varices sont importantes et présentes en grande surface sur les zones à traiter, le traitement est contre indiqué. Un examen préliminaire permettra d’évaluer l’importance du problème et d’adapter les zones à traiter. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel21'} onChange={handleChange('panel21')}>
        <AccordionSummary
            expandIcon={expanded === 'panel21'?<RemoveCircleOutlineIcon />:<AddCircleOutlineIcon />}
          aria-controls="panel21bh-content"
          id="panel21bh-header"
        >
          <Typography >
          Vous n’avez pas la réponse à votre question ? 
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          N'hésitez pas à nous contacter (<Link href="/contacts" > Nos contact <OpenInNewIcon sx={{fontSize:'18px'}}></OpenInNewIcon></Link>)
          </Typography>
        </AccordionDetails>
      </Accordion>
        </Grid>
    </Grid>
    </div>
    </section>

    </div>

  );
}
