import "./PeriNatalite.scss";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { useEffect, useState, useRef } from "react";
import Divider from '@mui/material/Divider';
import Button from "@mui/material/Button";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';


export default function PeriNatalite() {
  var scrollTop = 0;
  const [maxScrollTop, setMaxScrollTop] = useState(101);

  const reveal1 = useRef(null);
  const reveal2 = useRef(null);
  const reveal3 = useRef(null);
  const reveal4 = useRef(null);
  const reveal5 = useRef(null);
  useEffect(() => {
    const handleScroll = (event: any) => {
      scrollTop = Math.max(window.scrollY, scrollTop);
      setMaxScrollTop(scrollTop);
    };
    window.addEventListener("scroll", handleScroll);
    setMaxScrollTop(100);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getRectTop = (ref: any) => {
    return (
      ref?.current?.getBoundingClientRect().top +
      window.scrollY -
      document.documentElement.clientHeight +
      100
    );
  };

  const openLink = (link: string) => {
    window.open(link, "_blank");
  };
  return (
    <div className='fe-container-padtop fe-container-top-bg'>
      <section className='fe-container-fluid fe-section'>
        <Grid container spacing={2} ref={reveal1}>
          <Grid
            className={
              maxScrollTop > getRectTop(reveal1)
                ? "fe-l-slide reveal"
                : "fe-l-slide "
            }
            item
            md={8}
            xs={12}
            sx={{ display: "inline-block", pr: { md: "100px", xs: "0px" } }}
          >
            <Box sx={{ p: { md: "0px", xs: "20px" } }}>
              <Typography
                sx={{ fontStyle: "italic", mb: "1em" }}
                variant='h4'
                color='#98857E'
              >
                <Box sx={{ mr: "10px", float: "left" }}>
                  <img src='/media/comma.png'></img>
                </Box>
                Périnatalité, grossesse, post partum
              </Typography>
              <Box sx={{ pl: "36px" }}>
                <Typography variant='subtitle1' paragraph>
                  La grossesse et le post partum, sources de modifications
                  anatomiques et physiologiques, sont une période au cours de
                  laquelle des troubles périnéaux apparaissent fréquemment. Ils
                  ont un retentissement important sur la qualité de vie et la
                  santé des femmes.
                </Typography>
                <Typography variant='subtitle1' paragraph>
                  Avec le LPG et le drainage Renata França, nous vous aidons à
                  chaque étape, de la préparation de la grossesse jusqu’au
                  post-partum. Une grossesse plus détendue et bien accompagnée
                  favorise également le bon développement et système immunitaire
                  de votre bébé.
                </Typography>
                <Typography variant='subtitle1' paragraph>
                  Le drainage lymphatique méthode Renata França est également
                  proposé à domicile pendant la grossesse et après l’arrivée de
                  votre bébé !
                </Typography>

               
              </Box>
            </Box>
          </Grid>
          
          <Grid
            item
            md={4}
            xs={12}
            px={2}
            sx={{ textAlign: "center", m: { md: "0px", xs: "3em" } }}
          >
            <div
              className={
                maxScrollTop > getRectTop(reveal1)
                  ? "fe-r-slide reveal"
                  : "fe-r-slide "
              }
            >
              <div className='fe-photo-back'>
                <img
                  style={{ maxHeight: "450px" }}
                  src='/media/pregnant.jpg'
                ></img>
              </div>
            </div>
          </Grid>
          </Grid>
          </section>
          <section className=' fe-section fe-section-rose'>
         <div className=" fe-container-fluid">
         
          <Grid
            ref={reveal2}
            className={
              maxScrollTop > getRectTop(reveal2)
                ? "fe-l-slide reveal"
                : "fe-l-slide "
            }
            item
            xs={12}
          
          >
            <Box sx={{ pl: "36px" }}>
              <Typography
                sx={{ fontStyle: "italic", mb: "1em" }}
                variant='h4'
                color='#98857E'
              >
                RENATA FRANÇA véritable soin thérapeutique et esthétique
              </Typography>

              <Grid container spacing={3} justifyContent="center" >
          <Grid item xs={12}  >
            
          <Typography  variant="subtitle1" sx={{width:'100%',textAlign:'center'}}  paragraph><b>  En prénatal :  </b></Typography>
          <List  className="fe-list">
               
                <ListItem> <ListItemText primary="Aide à la conception en diminuant le stress, le  taux de cortisol et taux inflammatoire du corps, permettant une meilleure nidation." sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Détoxifie le corps, le système lymphatique  étant une “déchetterie” car la lymphe intervient dans l’épuration des toxines. Votre corps est donc purifié et plus performant. Plus sain pour concevoir et accueillir bébé." sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Draine l’excès de liquides lié aux traitements hormonaux (parcours PMA, pilule) et améliore l’équilibre hormonal." sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Améliore la circulation sanguine et lymphatique." sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Réduit les ballonnements et améliore le transit." sx={{textAlign:"center"}}/></ListItem>
            </List>
           
          </Grid>
          
          <Grid  item xs={12} >
          <Typography  variant="subtitle1" sx={{width:'100%',textAlign:'center'}}  paragraph><b>Pendant la grossesse :  </b></Typography>
          <List  className="fe-list ">  
          <ListItem> <ListItemText primary="Réduit les oedèmes, les gonflements."  sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Améliore la circulation sanguine et lymphatique." sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Lutte contre les jambes lourdes, prévient les varices. " sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary=" Permet un meilleur sommeil. " sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Soulage les tensions et douleurs. " sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Évite trop d’accumulation de toxines. " sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Diminue le stress et le taux de  cortisol donc réduit l’inflammation. " sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Bien-être. " sx={{textAlign:"center"}}/></ListItem>
            </List>
          </Grid>

          <Grid  item xs={12}  >
          <Typography  variant="subtitle1" sx={{width:'100%',textAlign:'center'}}  paragraph><b>En post partum :  </b></Typography>
          <List  className="fe-list ">  
          <ListItem> <ListItemText primary="Favorise la régénération des tissus, la cicatrisation, la récupération post-opératoire."  sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Améliore la circulation sanguine et lymphatique." sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Aide à une meilleure récupération globale post accouchement. " sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Action nettoyante et purifiante (toxines accumulées, traitements lors de l’accouchement…)." sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Réamorce le système lymphatique et évacue l’excès de liquides liés à la grossesse." sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Améliore le sommeil. " sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Réduit l’anxiété." sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Combat la cellulite aqueuse et les vergétures ( + adipeuse et fibreuse en cas de remodelage Renata França), affine et galbe la silhouette. " sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Dynamise le métabolisme." sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Permet un moment pour soi de bien-être." sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Raffermit la peau." sx={{textAlign:"center"}}/></ListItem>
            </List>
          </Grid>

          </Grid>
              
<br></br>
              <Typography variant='subtitle1' paragraph textAlign="center">
                À chaque stade, le drainage lymphatique méthode Renata França
                renforce le système immunitaire, <br></br>lutte contre les infections et
                permet de maintenir une bonne santé !
              </Typography>
          
            </Box>
          </Grid>
          </div>
          </section>
          <section className='fe-container-fluid fe-section '>
      
          <Grid
            ref={reveal3}
            className={
              maxScrollTop > getRectTop(reveal3)
                ? "fe-l-slide reveal"
                : "fe-l-slide "
            }
            item
           
            sx={{ width:"100%" }}
          >
            
              <Typography
                sx={{ fontStyle: "italic", mb: "1em" }}
                variant='h4'
                color='#98857E'
              >
                LPG Endermologie
              </Typography>
              
              
              <Grid container spacing={3} justifyContent="center" >
            <Grid item   >
            <Typography  variant="subtitle1" sx={{width:'100%',textAlign:'center'}}  paragraph><b>Le LPG Cellu M6 vous aidera à :  </b></Typography>
            <List  className="fe-list fe-list-rose">
                  <ListItem> <ListItemText primary=" Améliorer l’aspect de votre cicatrice de césarienne " sx={{textAlign:"center"}}/></ListItem>
                  <ListItem> <ListItemText primary="Retrouver plus rapidement l’aspect de votre corps" sx={{textAlign:"center"}}/></ListItem>
                  <ListItem> <ListItemText primary="Réduire tous les types de cellulite et les graisses résistantes, sans douleur et sans léser les tissus et vaisseaux." sx={{textAlign:"center"}}/></ListItem>
                  <ListItem> <ListItemText primary="Réduire de manière significative le relâchement cutané, notamment au niveau abdominal" sx={{textAlign:"center"}}/></ListItem>
               
              </List>
            </Grid>
          </Grid>

           
          </Grid>
          </section>
          <section className=' fe-section fe-section-rose'>
         <div className=" fe-container-fluid">
         
          <Grid
            ref={reveal4}
            className={
              maxScrollTop > getRectTop(reveal4)
                ? "fe-l-slide reveal"
                : "fe-l-slide "
            }
            item
            xs={12}
           
          >
            <Box sx={{ pl: "36px" }}>
              <Typography
                sx={{ fontStyle: "italic", mb: "1em" }}
                variant='h4'
                color='#98857E'
              >
                La kinésithérapie et le coaching sportif :
              </Typography>
              <Typography variant='subtitle1' paragraph>
                Pour une prise en charge globale dans votre cabinet, vous avez
                également la possibilité d’être accompagnée de manière
                individuelle par Christopher, kinésithérapeute, qui saura
                adapter vos exercices en tenant compte de votre niveau et de vos
                éventuels problèmes de santé.
              </Typography>

              <Typography variant='subtitle1' paragraph>
                Cet accompagnement peut se faire avant, pendant la grossesse
                et/ou dans la reprise progressive d’une activité sportive après
                l’accouchement et la rééducation du périnée, lorsque cela sera
                possible.
              </Typography>

              <Typography variant='subtitle1' paragraph>
                L’activité sportive est indispensable durant cette période (si
                elle n’est pas médicalement contre indiquée).
              </Typography>

<br></br>
          <Grid container spacing={3} justifyContent="center" >
            <Grid item xs={12}  >
            <Typography  variant="subtitle1" sx={{width:'100%',textAlign:'center'}}  paragraph><b> Des études ont montré que les femmes pratiquant une activité physique pendant leur grossesse :  </b></Typography>
            <List  className="fe-list">
                  <ListItem> <ListItemText primary="présentaient un risque réduit de 30% de développer un diabète gestationnel " sx={{textAlign:"center"}}/></ListItem>
                  <ListItem> <ListItemText primary="souffraient moins souvent de dépression " sx={{textAlign:"center"}}/></ListItem>
                  <ListItem> <ListItemText primary="étaient moins sujettes aux douleurs dorsales" sx={{textAlign:"center"}}/></ListItem>
                  <ListItem> <ListItemText primary="avaient moins de problèmes de digestion" sx={{textAlign:"center"}}/></ListItem>
                  <ListItem> <ListItemText primary="souffraient moins souvent d’incontinence urinaire après la grossesse" sx={{textAlign:"center"}}/></ListItem>
                  <ListItem> <ListItemText primary="prenaient moins de poids pendant la grossesse" sx={{textAlign:"center"}}/></ListItem>
                  <ListItem> <ListItemText primary="se sentaient moins fatiguées" sx={{textAlign:"center"}}/></ListItem>
                  <ListItem> <ListItemText primary="présentaient un risque réduit d’accouchement prématuré et de césarienne programmée " sx={{textAlign:"center"}}/></ListItem>
              </List>
            </Grid>
          </Grid>
          <br></br>

              <Typography variant='subtitle1' paragraph>
                C’est un excellent moyen de préparer l’accouchement en
                renforçant les muscles, le ventre et le périnée et en ayant une
                bonne condition physique. Vous retrouverez ensuite plus
                rapidement la forme.
              </Typography>

              <Typography variant='subtitle1' paragraph>
                L’activité sportive favorise une meilleure circulation sanguine
                et lymphatique et permet l’élimination des toxines, limite les
                gonflements et la rétention d’eau.
              </Typography>

              <Typography variant='subtitle1' paragraph>
                Les bébés dont la mère était active pendant la grossesse sont
                également en meilleure santé, ont un poids de naissance normal,
                un meilleur développement neurologique et un risque réduit de
                souffrir de détresse respiratoire lors de l’accouchement.
              </Typography>
              <br></br>
                <Link
                  href='#'
                  onClick={() => {
                    openLink(
                      "https://www.sports.gouv.fr/guide-de-la-pratique-sportive-pendant-la-maternite-824"
                    );
                  }}
                >
                
                  Guide de la pratique sportive pendant la maternité
                </Link>
            </Box>
          </Grid>
</div>
          </section>
          <section className=' fe-section fe-container-fluid'>
       
         
          <Grid
            ref={reveal5}
            className={
              maxScrollTop > getRectTop(reveal5)
                ? "fe-l-slide reveal"
                : "fe-l-slide "
            }
            item
            xs={12}
           
          >
            <Box sx={{ pl: "36px" }}>
              <Typography
                sx={{ fontStyle: "italic", mb: "1em" }}
                variant='h4'
                color='#98857E'
              >
                L’ostéopathie
              </Typography>
              <Typography variant='subtitle1' paragraph>
              Maxime, ostéopathe formé en périnatalité prend également en charge les femmes enceintes ainsi que les nourrissons. 

              </Typography>
              <Typography variant='subtitle1' paragraph>
              <b>Pour la maman :</b> soulage les douleurs et tensions de la grossesse et des suites de l’accouchement, permet de retrouver une bonne mobilité et posture, aide au bon positionnement des organes et aux troubles digestifs et urinaires et aide au positionnement du bébé lors de la grossesse.


              </Typography>
              <Typography variant='subtitle1' paragraph>
              <b>Pour le bébé :</b> cela permet d’éviter des maux liés à la grossesse et l’accouchement, libérer des blocages, corriger la posture, calmer les coliques, prévenir la plagiocéphalie déformante, améliorer les troubles de l’alimentation et de la déglutition, les pleurs.


              </Typography>
              <br></br>
                <Link
                  href='#'
                  onClick={() => {
                    openLink(
                      "https://naturellemaman.com/10-bonnes-raisons-demmener-votre-bebe-chez-un-osteopathe-apres-sa-naissance/"
                    );
                  }}
                >
                  Consulter un ostéopathe : ce précieux cadeau de naissance que vous pouvez faire à votre bébé
                </Link>

            </Box>
          </Grid>
        
      </section>
    </div>
  );
}
