
import { BrowserRouter as Router, Route, Routes ,useLocation, Navigate} from 'react-router-dom';
import './App.scss';

import ScrollTop from "./scrollTop";

import AdminLayout from './AdminLayout';
import MainLayout from './MainLayout';
import MainPage from './pages/MainPage';
import LPG from './pages/Lpg';
import Renata from './pages/Renata';
import Faq from './pages/Faq';
import Glossaire from './pages/Glossaire';
import Tarif from './pages/Tarif';
import Info from './pages/Info';
import PeriNatalite from './pages/PeriNatalite';
import Pressotherapie from './pages/Pressotherapie';
import CoachingSportif from './pages/CoachingSportif';
import Kine from './pages/Kine';
import Contacts from './pages/Contacts';
import Login from './authentication/login';


import  { useState ,useEffect} from 'react';

import authContext from "./authentication/auth.context";
import {AxiosInterceptor} from "./authentication/interceptor.context";
import PopupsAdmin from './admin/pages/PopupsAdmin';
import CommentsAdmin from './admin/pages/CommentsAdmin';

function App() {

  function isTokenExpired(token:any) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  
    const { exp } = JSON.parse(jsonPayload);
    const expired = Date.now() >= exp * 1000
    return expired
  }
 
  const [authenticated, setAuthenticated] = useState(localStorage.getItem('token') && !isTokenExpired(localStorage.getItem('token'))?true:false);


  console.info("222"+ authenticated);
  const ProtectedRoute = ({children}: any) => {
    
    if (!authenticated) {
      return <Navigate to="/Administration/LoginAdmin" replace />;
    }
    return children;
  };
  
  return (
    <authContext.Provider value={{ authenticated, setAuthenticated }}>
     
       <AxiosInterceptor>
    <Router>
      <ScrollTop />
      <Routes>
      <Route  path='/' element={< MainLayout />}>
          <Route  path='/'   element={<MainPage />} />
          <Route  path='/LPG'   element={<LPG />} />
          <Route  path='/RenataFranca'   element={<Renata />} />
          <Route  path='/RenataFranca/:section'   element={<Renata />} />
          <Route  path='/Kine'   element={<Kine />} />
          <Route  path='/CoachingSportif'   element={<CoachingSportif />} />
          <Route  path='/Pressotherapie'   element={<Pressotherapie />} />
          <Route  path='/PeriNatalite'   element={<PeriNatalite />} />
          <Route  path='/Info'   element={<Info />} />
          <Route  path='/Tarif'   element={<Tarif />} />
          <Route  path='/Faq'   element={<Faq />} />
          <Route  path='/Glossaire'   element={<Glossaire />} />
          <Route  path='/Contacts'   element={<Contacts />} />
         
         
        </Route>
        <Route  path='/Administration' element={< AdminLayout />}>
          <Route  path='/Administration/LoginAdmin'   element={<Login  />} />
          <Route  path='/Administration/Promo'   element={
            <ProtectedRoute >
                <PopupsAdmin />
              </ProtectedRoute>
            } />
             <Route  path='/Administration/Comments'   element={
            <ProtectedRoute >
                <CommentsAdmin />
              </ProtectedRoute>
            } />
        </Route>
       
        
        <Route path="*"  element={<NoMatch />} />
    </Routes>
  </Router>
  </AxiosInterceptor>
  </authContext.Provider>
  );
}

function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

       

export default App;
