import { Link, Outlet } from 'react-router-dom';
import './AdminLayout.scss';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AdminMenu from './admin/components/AdminMenu';
import { useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { createContext } from "react";
import LoadingContext from './authentication/loading.context';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'


const theme = createTheme({
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 16,
    fontFamily: 'MontserratRegular'
      
  },
  palette: {
    background: {
      paper: "#fff",
    },
    text: {
      primary: "#1A1615",
      secondary: "#98857E",
     
    },
    action: {
      active: "#675436",
    },
    primary: {
      main: "#BCA47E",
    },
    info: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#ddd",
    },
  },
});
// marginTop:{xs:"160px",sm:"160px",md:"80px",xl:"0"}
function AdminLayout() {

  const [loadingDrop, setLoadingDrop] = useState(false);
  
  

 

  return (
  <div className='fe-admin-layout'>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) =>  Math.max.apply(Math, Object.values(theme.zIndex)) + 1 }}
      open={loadingDrop}
      
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    <LoadingContext.Provider value={{ loadingDrop, setLoadingDrop }}>
    <ThemeProvider theme={theme}>
      <AdminMenu></AdminMenu>
      <div className='fe-admin-container '>
        <Outlet />
      </div>
    </ThemeProvider>
    </LoadingContext.Provider>
    </LocalizationProvider>
  </div>
  );
}

export default AdminLayout;
