
import { Link as RouterLink } from "react-router-dom";
import * as React from "react";
import "./Footer.scss";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';
import Button from "@mui/material/Button";
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InstagramIcon from '@mui/icons-material/Instagram';
import PlaceIcon from '@mui/icons-material/Place';
import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FacebookIcon from '@mui/icons-material/Facebook';


function Footer() {

  const  openLink =(link:string)=>{
    window.open(link,'_blank');
  }
  return (
   <div  className="fe-footer ">
    <div className="fe-container-fluid">

    
    <Grid container id="section-contacts" >
      <Grid item xs={12} md={4} sx={{p:2}}>
        <Typography variant="subtitle1" color="#fff"  gutterBottom>
        Contact :
        </Typography>
              <Grid container spacing={1} sx={{mt:0.5}}>
                <Grid item>
                <IconButton
                    size='small'
                    href="tel:0422792926"
                    color='inherit' sx={[ { color: "#ccc", display: "flex" }, { "&:hover": {  color: "#fff"}}]}>
                      <LocalPhoneIcon />
                  </IconButton>
                </Grid>
                <Grid item sm  sx={{alignItems: 'center',display:'flex'}}>
                  <Typography  variant="subtitle1" color="primary" > 04 22 79 29 26 </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1} sx={{mt:0.5}}>
                <Grid item>
                <IconButton
                    size='small'
                    href="mailto:chrisferreira@live.fr"
                    color='inherit' sx={[ { color: "#ccc", display: "flex" }, { "&:hover": {  color: "#fff"}}]}>
                      <EmailIcon />
                  </IconButton>
                </Grid>
                <Grid item sm  sx={{alignItems: 'center',display:'flex'}}>
                  <Typography  variant="subtitle1" color="primary" >  chrisferreira@live.fr </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={{mt:0.5}}>
                <Grid item>
                <IconButton
                    size='small'
                    onClick={()=>openLink("https://www.instagram.com/lpg.toulon/")}
                    color='inherit' sx={[ { color: "#ccc", display: "flex" }, { "&:hover": {  color: "#fff"}}]}>
                      <InstagramIcon />
                  </IconButton>
                </Grid>
                <Grid item sm  sx={{alignItems: 'center',display:'flex'}}>
                  <Typography  variant="subtitle1" color="primary" > lpg.toulon </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={{mt:0.5}}>
                <Grid item>
                <IconButton
                    size='small'
                    onClick={()=>openLink("https://www.facebook.com/profile.php?id=100094746024855")}
                    color='inherit' sx={[ { color: "#ccc", display: "flex" }, { "&:hover": {  color: "#fff"}}]}>
                      <FacebookIcon />
                  </IconButton>
                </Grid>
                <Grid item sm  sx={{alignItems: 'center',display:'flex'}}>
                  <Typography  variant="subtitle1" color="primary" >Toulon LPG  </Typography>
                </Grid>
              </Grid>
            
         
      </Grid>
     
      <Grid item xs={12} md={4} sx={{borderLeft:{md:"2px solid #333",xs:""},borderRight:{md:"2px solid #333",xs:""},p:2}}>
        <Typography variant="subtitle1" color="#fff"  gutterBottom>
        Notre cabinet : <br/> 
        <Grid container spacing={1} sx={{mt:0.5}}>
          <Grid item>
          <IconButton
              size='small'
              onClick={()=>openLink("https://goo.gl/maps/eWRuX1Pj59J3dGGv6")}
              color='inherit'  sx={[ { color: "#ccc", display: "flex" }, { "&:hover": {  color: "#fff"}}]}>
                <PlaceIcon />
            </IconButton>
          </Grid>
          <Grid item sm  >
            <Typography  variant="subtitle1" color="primary" >1, rue Eugène sylvain</Typography>
            <Typography  variant="subtitle1" color="primary" > 83000 Toulon</Typography>
            <Button size="small" sx={{mt:0.5}} variant="outlined" color="secondary" onClick={()=>openLink("https://goo.gl/maps/eWRuX1Pj59J3dGGv6")} endIcon={<ArrowOutwardIcon />}>
            Ouvrir sur Google Maps
          </Button>  
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{mt:0.5}}>
          <Grid item>
          
                <DirectionsBusFilledIcon sx={[ { color: "#fff", mx: 0.5 }]}/>
  
          </Grid>
          <Grid item sm  >
            <Typography  variant="subtitle1" color="primary" >Bus : arrêt Bir Hakeim</Typography>
            <Typography  variant="subtitle1" color="primary" >9 / 39 / 102 / 103 / 191</Typography>
          </Grid>
        </Grid>
        
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}  sx={{p:2}} >
        <Typography variant="subtitle1" color="#fff"  gutterBottom>
        Horaires :
        </Typography>
        <Grid container spacing={1} sx={{mt:0.5}}>
          <Grid item>
         
                <CalendarMonthIcon  sx={[ { color: "#fff", mx: 0.5 }]}/>
            
          </Grid>
          <Grid item sm >
            <Typography  variant="subtitle1" color="primary" > Du lundi au vendredi, sur rdv</Typography>
            
          </Grid>
        </Grid>
        <Box sx={{display:'inline-flex',pt:2}}>
          <a href="https://www.doctolib.fr/masseur-kinesitherapeute/toulon/christopher-ferreira/booking?utm_campaign=website-button&amp;utm_source=christopher-ferreira-website-button&amp;utm_medium=referral&amp;utm_content=withoutpreview-blue-floating-bottom-right&amp;utm_term=christopher-ferreira" 
            className="doctor" target="_blank"> 
             Prendre RDV sur <img style={{height:'19px',verticalAlign:'middle',width:'auto'}} src='https://pro.doctolib.fr/external_button/doctolib-white-transparent.png' alt='Doctolib'/>
             </a>
        </Box>
       
        
      </Grid>
    </Grid>
    </div>
        </div>
  );
}

export default Footer;
