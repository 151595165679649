import { Link as RouterLink } from "react-router-dom";
import * as React from "react";
import "./Header.scss";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import InfoIcon from '@mui/icons-material/Info';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import {useEffect, useState} from 'react';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { ListItem } from "@mui/material";
import { Link } from "react-router-dom";

import api from '../authentication/interceptor.context';

const legoPages = [
  { name: "Hypotenuse",desc:"Find possible liftarms connections", to: "/hypotenuse" },
  { name: "Angle Connections",desc:"Connections between 2 liftarms ", to: "/angles" },
  { name: "Gears",desc:"Combine multiples gears", to: "/gears" },
];


const aboutPages = [
  { name: "About Me",desc:"Find more about me", to: "/about" , icon: <InfoIcon  />},
  { name: "Support Me",desc:"Support this project", to: "/support", icon: <ThumbUpIcon /> }
];

function Header() {
  const [scrollTop, setScrollTop] = useState(101);
  const [promo, setPromo] = useState(false);

  const [promoElem, setPromoElem] = useState(null as any);

  useEffect(() => {
    const handleScroll = (event:any) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

   

    api.get('/popups')
    .then(function (response) {
      try{
        const promo = response.data.find((a:any)=>a.type === "top_promo")
        setPromoElem(promo);
        setPromo(promo.visible || false);
        console.log(promoElem)
      }
      catch{
        setPromo(false);
      }
   
    })
    .catch(function (error:any) {
      setPromo(false);
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const  openLink =(link:string)=>{
    window.open(link,'_blank');
  }
  
 





  const [openRenataDrawer, setOpenRenataDrawer] = useState(false);
  const handleOpenRenataDrawer  =  (open: boolean) =>
  (event: React.KeyboardEvent | React.MouseEvent) => {
    setOpenRenataDrawer(open);
    return ;
  }
  const [openInfoDrawer, setOpenInfoDrawer] = useState(false);
  const handleOpenInfoDrawer  =  (open: boolean) =>
  (event: React.KeyboardEvent | React.MouseEvent) => {
    setOpenInfoDrawer(open);
    return ;
  }
// menu



// end menu
  
  

 
  const goToSection = (section:string) => {
    let element = document.getElementById('section-'+section);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
    
  };
  const renataList =(
    <>

    <ListItem disablePadding >
    <ListItemButton component={Link} to="/RenataFranca/DrainageLymphatique" onClick={handleOpenRenataDrawer( false)}>
      <ListItemText primary="Drainage lymphatique corps" />
    </ListItemButton>
  </ListItem>
  <Divider />
  <ListItem disablePadding>
    <ListItemButton component={Link} to="/RenataFranca/Miracleface" onClick={handleOpenRenataDrawer( false)}>
      <ListItemText primary="Miracle face" />
    </ListItemButton>
  </ListItem>
  <Divider />
  <ListItem disablePadding>
    <ListItemButton component={Link} to="/RenataFranca/Remodelage" onClick={handleOpenRenataDrawer( false)}>
      <ListItemText primary="Remodelage" />
    </ListItemButton>
  </ListItem>
  </>
     

  )
  const infoList = (
    <>
  <ListItem disablePadding >
    <ListItemButton component={Link} to="/Info"  onClick={handleOpenInfoDrawer( false)}>
      <ListItemText primary="Qui somme nous ?" />
    </ListItemButton>
  </ListItem>
  <Divider />
  <ListItem disablePadding>
    <ListItemButton  component={Link} to="/Tarif" onClick={handleOpenInfoDrawer( false)}>
      <ListItemText primary="Nos Tarifs" />
    </ListItemButton>
  </ListItem>
  <Divider />
  <ListItem disablePadding>
    <ListItemButton component={Link} to="/Faq" onClick={handleOpenInfoDrawer( false)}>
      <ListItemText primary="Questions Fréquentes" />
    </ListItemButton>
  </ListItem>
  <Divider />
  <ListItem disablePadding>
    <ListItemButton  component={Link} to="/Glossaire" onClick={handleOpenInfoDrawer( false)}>
      <ListItemText primary="Glossaire" />
    </ListItemButton>
  </ListItem>
  <Divider />
  <ListItem disablePadding>
    <ListItemButton  component={Link} to="/Contacts" onClick={handleOpenInfoDrawer( false)}>
      <ListItemText primary="Contacts" />
    </ListItemButton>
  </ListItem></>
  )
  const menu = (<>
  <Button
    variant='text'
    component={Link} to="/Lpg"
    sx={{textAlign:"center", px:{ md: 1 , lg:2}}}
    >
    LPG
  </Button>
  <Divider  sx={{ my: 3 }} orientation="vertical" variant="middle" flexItem />
  <div className="fe-dropdown">
    
 
  <Button
    variant='text'
    component={Link} to="/RenataFranca"
    sx={{textAlign:"center", px:{ md: 1 , lg:2}}}
    >
    Renata França
  </Button>

    <List  dense={true} className="fe-dropdown-content" sx={{  bgcolor: 'background.paper' }}   component="nav">
    {renataList}
    </List>
   
   </div>
  <Divider  sx={{ my: 3 }} orientation="vertical" variant="middle" flexItem />
    <Button
    variant='text'
    component={Link} to="/Kine"
    sx={{textAlign:"center", px:{ md: 1 , lg:2}}}
   >
   KINE
  </Button>
  <Divider  sx={{ my: 3 }} orientation="vertical" variant="middle" flexItem />
  <Button
    variant='text'
    component={Link} to="/CoachingSportif"
    sx={{textAlign:"center", px:{ md: 1 , lg:2}}}
    >
    COACHING SPORTIF
  </Button>
  <Divider  sx={{ my: 3 }} orientation="vertical" variant="middle" flexItem />
  <Button
    variant='text'
    component={Link} to="/Pressotherapie"
    sx={{textAlign:"center", px:{ md: 1 , lg:2}}}
    >
    PRESSOTHÉRAPIE 
  </Button>
  <Divider  sx={{ my: 3 }} orientation="vertical" variant="middle" flexItem />
  <Button
    variant='text'
    component={Link} to="/PeriNatalite"
    sx={{textAlign:"center", px:{ md: 1 , lg:2}}}
    >
    PERINATALITE 
  </Button>
  <Divider  sx={{ my: 3 }} orientation="vertical" variant="middle" flexItem />
  <div className="fe-dropdown">
  <Button
    variant='text'
    sx={{textAlign:"center", px:{ md: 1 , lg:2}}}
    >
    À PROPOS
  </Button>
  <List  dense={true} className="fe-dropdown-content" sx={{  bgcolor: 'background.paper' }} component="nav">
  {infoList}
  </List>
  
  </div>
</>)
  //  BLACK FRIDAY -50% sur les cures LPG et França Renata <small>(valable jusqu’au 24 Novembre)</small> 

  return (
    <div className="fe-header-container">
      {promo &&
        <div className="fe-promo">
        <span>{promoElem.label}</span>
          </div>
      }
     
    <AppBar className={scrollTop>0?'fe-header scrolled':'fe-header'}>
      <Toolbar sx={{flexDirection:"column"}}> 
        <div className='fe-header-fluid'>
          <Box  sx={{ display: { xs: "none", md: "flex" }, mr: { md:0, lg:3 }, alignItems: "center",   }}>
            <RouterLink to="/">
            <img style={{ maxHeight: "70px" }} src='/media/mainlogo.png' ></img>
             </RouterLink>
             
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" },alignItems: "center" }} >
            <img style={{ maxHeight: "50px",marginRight:'10px' }} src='/media/lpg.png' ></img>
          </Box>
          <Box sx={{  display: { xs: "flex", md: "none" }, mr: 1, flexGrow: 1, alignItems: "center" }} >
            <img  style={{ maxHeight: "60px" }}  src='/media/mainlogo.png' ></img>
          </Box>
          <Box sx={{alignItems: "center", flexGrow: 1,ml: { md:0, lg: 1 }, display: { xs:"none",sm:"none", md: "none", lg: "flex" } }}>
         {menu}
         </Box>
         <Box sx={{alignItems: "center", flexGrow: 1,ml: { md:0, lg: 1 }, display: {  xs:"none",sm:"none",md: "flex", lg: "none" } }}>
         </Box>
          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" },alignItems: "center" }} >
          <Tooltip title={'Appeler'} >
              <IconButton
               component={Link} to="/Contacts"
                size='large'
                color='inherit'
              >
                  <LocalPhoneIcon />
              </IconButton>
            </Tooltip>
            
          </Box>
          <Box sx={{ flexGrow: 0, alignItems: "center", display: "flex" }}>
            <Box   sx={{ display: { xs: "none", md: "flex" } , mr: 4}}>
            <Box   sx={{  m: 1}}>
            <Tooltip title={'Prendre RDV'}>
            <a href="https://www.doctolib.fr/masseur-kinesitherapeute/toulon/christopher-ferreira/booking?utm_campaign=website-button&amp;utm_source=christopher-ferreira-website-button&amp;utm_medium=referral&amp;utm_content=withoutpreview-blue-floating-bottom-right&amp;utm_term=christopher-ferreira" 
            className="doctor" target="_blank"> 
             <img style={{height:'19px',verticalAlign:'middle',width:'auto'}} src='https://pro.doctolib.fr/external_button/doctolib-white-transparent.png' alt='Doctolib'/>
             </a>
             </Tooltip>
             </Box>
         
           <img style={{ maxHeight: "50px" }} src='/media/lpg.png' ></img>
           </Box>
           
          </Box>
        </div>
       
        <Box  sx={{ borderTop:"1px solid #BCA47E33",marginTop:"1em", width: "100%",justifyContent:"space-evenly",alignItems:"center",display: { xs:"none",sm:"none",md: "flex", lg: "none" } }}>
         {menu}
         </Box>
      </Toolbar>

  
     
    </AppBar>
    <div className={scrollTop>0?'fe-small-menu scrolled':'fe-small-menu'} >
    <div className="fe-small-menu-line">
      <Link  className="fe-small-link"   to="/Lpg">LPG</Link>
      <div className="fe-small-link" onClick={handleOpenRenataDrawer(true)}>RENATA FRANÇA</div>
      <Link  className="fe-small-link"   to="/Kine">KINE</Link>
      <Link  className="fe-small-link"   to="/CoachingSportif">COACHING SPORTIF</Link>
      
      </div>
      <div className="fe-small-menu-line">
      <Link  className="fe-small-link"   to="/Pressotherapie">PRESSOTHÉRAPIE</Link>
      <Link  className="fe-small-link"   to="/PeriNatalite">PERINATALITE </Link>
        <div className="fe-small-link"  onClick={handleOpenInfoDrawer(true)}>Info </div>
      </div>
     
    </div>

    <Drawer
      anchor="top"
      open={openRenataDrawer}
      onClose={handleOpenRenataDrawer( false)}
    >
       <List  sx={{  bgcolor: 'background.paper' }} component="nav">
  {renataList}
  </List>
         
    </Drawer>
    <Drawer
      anchor="top"
      open={openInfoDrawer}
      onClose={handleOpenInfoDrawer( false)}
    >
        <List  sx={{  bgcolor: 'background.paper' }} component="nav">
  {infoList}
  </List>
        
    </Drawer>
    </div>
  );
}

export default Header;
