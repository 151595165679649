import {Link, Avatar, Card, CardContent, CardHeader, Grid, Rating, Typography, Box, CardActions, Button, IconButton, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, AlertColor, Snackbar, Alert } from '@mui/material';
import api from '../../authentication/interceptor.context';
import {useContext, useEffect, useState} from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import GoogleIcon from '@mui/icons-material/Google';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DatePicker } from '@mui/x-date-pickers';
import { MuiColorInput } from 'mui-color-input'
import LoadingContext from '../../authentication/loading.context';
import dayjs from 'dayjs';

export default function CommentsAdmin() {

  const [comments, setComments] = useState([] as any[]);
  const {loadingDrop, setLoadingDrop} = useContext(LoadingContext);

  const newComment =  {id:null,name: "",comment:"",color:"",date:null,rating:1};

    useEffect(() => {
      chargeComments();
      }, []);
      const openLink = (link: string) => {
        window.open(link, "_blank");
      };
      
      const chargeComments = () => {
        setLoadingDrop(true);
        api.get('/comments')
          .then(function (response) {
            setComments(response.data);
            setLoadingDrop(false);
          })
          .catch(function (error) {
            console.log(error);
            setComments([]);
            setLoadingDrop(false);
          });
      };
      
      const saveComments = () => {
        setLoadingDrop(true);

        const datatoSend : any = {...editNewComment};
        datatoSend.date =  datatoSend?.date?.$D + "/" +  (datatoSend?.date?.$M + 1) + "/" +  datatoSend?.date?.$y
        if(datatoSend.id){
          api.put('/comments/'+datatoSend.id,datatoSend)
          .then(function (response) {
            chargeComments();
            handleCloseDialog();
            setOpenAlert({...openAlert,message:"Modifications efféctué!",open: true,severity: "success"});
          })
          .catch(function (error) {
            setLoadingDrop(false);
            handleCloseDialog();
            setOpenAlert({...openAlert,message:"Erreur durant la sauvegarde!",open: true,severity: "success"});
          });
        }
        else{
       
          api.post('/comments',datatoSend)
            .then(function (response) {
              chargeComments();
              handleCloseDialog();
              setOpenAlert({...openAlert,message:"Sauvegarde efféctué!",open: true,severity: "success"});
            })
            .catch(function (error) {
              setLoadingDrop(false);
              handleCloseDialog();
              setOpenAlert({...openAlert,message:"Erreur durant la sauvegarde",open: true,severity: "success"});
            });
        }
      };

      const [openAlert, setOpenAlert] = useState({message:"",severity:"success" as AlertColor ,open:false});
      const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert({...openAlert,open: false});
      };

      const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
      const handleOpenDialogConfirm = (id : string) => {
        setDeleteId(id);
        setOpenDialogConfirm(true);
      };

      const handleCloseDialogConfirm = () => {
        setDeleteId("");
        setOpenDialogConfirm(false);
      };
      const [deleteId, setDeleteId] = useState("");
      const deleteComment = () => {
        setLoadingDrop(true);
       
        if(deleteId){
          api.delete('/comments/'+deleteId)
          .then(function (response) {
            chargeComments();
            handleCloseDialogConfirm();
            setOpenAlert({...openAlert,message:"Comentaire suprimer!",open: true,severity: "success"});
          })
          .catch(function (error) {
            setLoadingDrop(false);
            handleCloseDialogConfirm();
            setOpenAlert({...openAlert,message:"Erreur durant la supression!",open: true,severity: "error"});
          });
        }
       
      };
      const [openDialog, setOpenDialog] = useState(false);

      const [editNewComment, setEditNewComment] = useState(newComment );

      const handleOpenDialog = () => {
        setOpenDialog(true);
      };

      const handleCloseDialog = () => {
        setOpenDialog(false);
      };
      const handleSaveComment = () => {
        
      };
      const formatDate = (datetime : string): string =>{
        
        const dateParts = datetime.split('/');
        return dateParts[2] +"/"+ dateParts[1]  +"/"+ dateParts[0];
      }
      const handleEditNewComment = (comment:any) => {
        if(comment.date)
        {
          comment.date =dayjs(formatDate(comment.date)); 
        }
        setEditNewComment(comment);
        setOpenDialog(true);
      };

      const handleTextChange = (e:any) => {
        setEditNewComment({
          ...editNewComment,
          [e.target.name]: e.target.value
        });
      };
      const handleDateChange = (newValue:any) => {
        console.log(newValue)
        setEditNewComment({
          ...editNewComment,
          date: newValue
        });
      };
      const handleColorChange = (newValue:any) => {
        console.log(newValue);
        setEditNewComment({
          ...editNewComment,
          color: newValue
        });
      }
      const handleRatingChange = (newValue:any ) => {
        console.log(newValue);
        setEditNewComment({
          ...editNewComment,
          rating: newValue
        });
      }
      
  return ( 
    

         <Box p={4}>
         <Grid container spacing={3}  justifyContent="left" >
              {comments.map((item, i) =>
               <Grid key={i} item xs={12} sm={12} md={6} lg={4} >
               <Card elevation={6}>
             <CardHeader
               avatar={
                 <Avatar sx={{ bgcolor: item.color }} aria-label="recipe">
                   {item.name.substring(0,1)}
                 </Avatar>
               }
               title={item.name}
               subheader={item.date}
             />
             
             <CardContent>
             <Rating name="read-only" value={item.rating} readOnly />
               <Typography variant="body2" className='display-linebreak' color="text.secondary">
               {item.comment}
              
               </Typography>
             </CardContent>
             <CardHeader
               avatar={
                 <GoogleIcon color="primary"></GoogleIcon>
               }
               title={<Typography fontSize={12} variant="caption">Poster sur <Link fontSize={12} href="#" onClick={()=>{openLink("https://www.google.com/search?q=Violaine+Granier+Renata+Fran%C3%A7a+LPG&oq=v&gs_lcrp=EgZjaHJvbWUqBggBEEUYOzIGCAAQRRg5MgYIARBFGDsyBggCEEUYPTIGCAMQRRg9MgYIBBBFGD0yBggFEEUYQTIGCAYQRRhBMgYIBxBFGDzSAQg1ODM4ajBqN6gCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x12c91bc947b10c69:0x1db5c57daf2c3838,1,,,,")}}>Google</Link></Typography>}
              
             />
             <Divider/>
             <CardActions>
             <IconButton onClick={()=>handleEditNewComment({...item})} aria-label="share">
              <EditIcon />
            </IconButton>
            <IconButton onClick={()=>handleOpenDialogConfirm(item.id)} aria-label="share">
              <DeleteForeverIcon />
            </IconButton>
            </CardActions>
           </Card>
           </Grid>
          )}
           <Grid  item xs={12} sm={12} md={6} lg={4} >
               <Card elevation={6}>
               <CardContent sx={{textAlign:"center"}} >
               <IconButton onClick={()=>handleEditNewComment({...newComment})} sx={{my:8}} aria-label="share">
                <AddCircleIcon sx={{ fontSize: 80 }}/>
              </IconButton>
               </CardContent>
               </Card>
               </Grid>
          </Grid>

          <Dialog open={openDialog}  maxWidth="lg" onClose={handleCloseDialog}>
        <DialogTitle>{editNewComment?.id ? "Edition de Commentaires":"Nouveau Commentaires"}</DialogTitle>
        <DialogContent>
        <Grid container spacing={3}>
            <Grid item xs={6}>
            <TextField
            sx={{mt:3}}
            autoFocus
            value={editNewComment.name}
            onChange={handleTextChange}
            name="name"
            label="Nom Complet"
            type="text"
            fullWidth
            variant="outlined"
            autoComplete="off"
          />            
                <DatePicker label="Date" 
                 sx={{mt:3,width:"100%"}}
                 format="DD/MM/YYYY"
                value={editNewComment.date}
                onChange={(newValue) => {
                  handleDateChange(newValue)
                }} 
                name="date"
              />
            
                <MuiColorInput label="Couleur" fullWidth sx={{mt:3}}  value={editNewComment.color} format="hex" onChange={handleColorChange} />
            
              <Rating 
           sx={{mt:3}}
            value={editNewComment.rating}
            onChange={(event, newValue) => {
              handleRatingChange(newValue);
            }}
            name="rating"/>
            
            </Grid>
            <Grid item xs={6}>
            <TextField
            autoComplete="off"
           multiline
           sx={{mt:3}}
            value={editNewComment.comment}
            onChange={handleTextChange}
            name="comment"
            label="Commentaires"
            type="text"
            fullWidth
            rows={7}
            variant="outlined"
          />
            </Grid>
        </Grid>


           
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button disabled={!editNewComment.comment ||!editNewComment.name ||!editNewComment.date || editNewComment.rating === null} onClick={saveComments}>Sauveguarder</Button>
        </DialogActions>
      </Dialog>



      <Dialog open={openDialogConfirm}  maxWidth="lg" onClose={handleCloseDialogConfirm}>
        <DialogTitle>Supression de comentaire</DialogTitle>
        <DialogContent>
        <DialogContentText>
            Voulez-vous vraiment effacer ce comentaires?
          </DialogContentText>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button onClick={handleCloseDialogConfirm}>Annuler</Button>
          <Button  onClick={deleteComment}>Suprimer</Button>
        </DialogActions>
      </Dialog>

      <Snackbar  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={openAlert.open} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert elevation={6} onClose={handleCloseAlert} severity={openAlert?.severity || ""} sx={{ width: '100%' }}>
         {openAlert?.message || ""}
        </Alert>
      </Snackbar>
          </Box>


  );
}
