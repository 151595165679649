import { Alert, AlertColor, Box, Button, CircularProgress, FormControlLabel, Snackbar, Switch, TextField } from '@mui/material';
import api from '../../authentication/interceptor.context';
import React, {useEffect, useState} from 'react';


export default function PopupsAdmin() {

  const [promoElem, setPromoElem] = useState(null as any);
  const [promoLabel, setPromoLabel] = useState("");
  const [saving, setSaving] = useState(false);
  const [openAlert, setOpenAlert] = useState({message:"",severity:"success" as AlertColor ,open:false});
    useEffect(() => {
    
      api.get('/popups')
      .then(function (response) {
        try{
          const promo = response.data.find((a:any)=>a.type === "top_promo")
          promo.visible =  promo.visible ?true:false;
          setPromoElem(promo);
          setPromoLabel(promoElem?.label);
        }
        catch{
       
        }
     
      })
      .catch(function (error:any) {
       
      });
  
      }, []);

      const handleChange = (e:any) => {
        setPromoElem({
          ...promoElem,
          label: e.target.value
        });
      };
      const handleSwitchChange = (e:any) => {
        setPromoElem({
          ...promoElem,
          visible: e.target.checked
        });
      };

      const save = (e:any) => {
        setSaving(true);
        api.put('/popups/'+promoElem.id,promoElem)
        .then(function (response) {
          
            setSaving(false);
            setOpenAlert({...openAlert,message:"Sauvegarde efféctué!",open: true,severity: "success"});
        })
        .catch(function (error:any) {
          setSaving(false);
          setOpenAlert({...openAlert,message:"Erreur durant la sauvegarde!",open: true,severity: "error"});
        });
      };
      const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlert({...openAlert,open: false});
      };
    
  return ( 
    
  <Box p={4}>
    <div className="fe-promo">
      <span>{promoElem?.label}</span>
    </div>
    {promoElem && 
    <Box pt={2} >
    <TextField label="Text" value={promoElem.label} sx={{background:"#ffffff",width:"100%"}} className='fe-text-field-center' onChange={handleChange} type="text"  size="small"  />
    <FormControlLabel
          control={
            <Switch onChange={handleSwitchChange}  checked={promoElem?.visible} />
          }
          label="Visible"
        />
  
    </Box>
    }
     <Button onClick={save} style={{width:"300px"}} disabled={saving} variant="contained" color='info'>
      {saving ? 
           <CircularProgress size={28}/>:
          <span>Enregistrer</span> 
      }
      </Button>
      <Snackbar  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={openAlert.open} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert elevation={6} onClose={handleCloseAlert} severity={openAlert?.severity || ""} sx={{ width: '100%' }}>
         {openAlert?.message || ""}
        </Alert>
      </Snackbar>
     
  </Box>

  );
}
