import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";

import Paper from '@mui/material/Paper';
import authContext from "../authentication/auth.context";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import UserService from "../authentication/user.service";
import LoadingContext from './loading.context';
import { Alert } from '@mui/material';
const userService = new UserService();

const Login = () => {

  const navigate = useNavigate();
  
  const [credentials, setCredentials] = useState({
    user: '',
    password: ''
  });

  const {authenticated, setAuthenticated} = useContext(authContext);
  const {loadingDrop, setLoadingDrop} = useContext(LoadingContext);
  const [loginError, setLoginError] = useState("");
  
  useEffect(() => {
   if(authenticated)
   {
    navigate("/Administration/Promo");
   }
 
  }, [authenticated]);
  
 

  const handleChange = (e:any) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit =  (e:any) => {
    
    e.preventDefault();
    setLoadingDrop(true);
    setLoginError("");
    try {
      userService.login(credentials).then(()=>{
        setAuthenticated(true);
        navigate("/Administration/Promo");
        setLoadingDrop(false);
      }).catch((error:any) =>{
        // Handle login error
        setAuthenticated(false);
        setLoadingDrop(false);
        console.log(error);
        setLoginError(error.response.data.message || error.message || "Erreur de Serveur!");
      });
    
    } catch (error) {
      // Handle login error
      setAuthenticated(false);
      setLoadingDrop(false);
      console.log(error);
      setLoginError("Erreur de Serveur!");
    }
  };

  return (

    <div >
      {authenticated}
      <section  className="fe-container-fluid" style={{justifyContent:"center",minHeight:"100vh",alignItems:"center"}}>
      <Paper elevation={3}  sx={{padding:"2em"}} >
      
      <form onSubmit={handleSubmit} >
        <Grid container spacing={3}    >
            <Grid   item>
            <TextField value={credentials.user} onChange={handleChange} type="text" name="user" label="Utilisateur" size="small" variant="outlined" />
            </Grid>
            <Grid   item>
            <TextField value={credentials.password} onChange={handleChange} type="password" name="password" size="small" label="Mot de Passe" variant="outlined" />
            </Grid>
            <Grid   item>
            <Button disabled={!credentials.user || !credentials.password } type="submit" style={{color:'#fff'}} variant="contained">Login</Button>
            </Grid>
        </Grid>

        </form>{
          loginError && <Alert sx={{marginTop:"1em"}} severity="error">{loginError}</Alert>
        }
        </Paper>
      </section>
    </div>
  );
};

export default Login;