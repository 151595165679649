import "./Faq.scss";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';
import Button from "@mui/material/Button";
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InstagramIcon from '@mui/icons-material/Instagram';
import PlaceIcon from '@mui/icons-material/Place';
import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import {useEffect, useState,useRef} from 'react';


export default function Contacts() {
  var scrollTop = 0;
  const [maxScrollTop, setMaxScrollTop] = useState(101);

  const reveal1 = useRef(null);

  useEffect(() => {
    const handleScroll = (event:any) => {
      scrollTop = Math.max(window.scrollY,scrollTop);
      setMaxScrollTop(scrollTop);
    };
    window.addEventListener('scroll', handleScroll);
    setMaxScrollTop(100);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getRectTop = (ref:any)=>{
    return ref?.current?.getBoundingClientRect().top  + window.scrollY -  document.documentElement.clientHeight + 100 };

  const  openLink =(link:string)=>{
    window.open(link,'_blank');
  }



  return ( 
    
    <div className="fe-container-padtop fe-container-top-bg">
    <section  className="fe-container-fluid fe-section">
    <Grid container spacing={2} ref={reveal1}>
        <Grid  className={(maxScrollTop > getRectTop(reveal1))?"fe-l-slide reveal":"fe-l-slide " }  item lg={4} xs={12} sx={{display:'inline-block',pr:{md:'100px',xs:'0px'}}}>
          <Box sx={{p:{md:'0px',xs:'20px'}}}>
          <Typography sx={{fontStyle:'italic',mb:'1em'}} variant="h4" color="#98857E" >
          <Box sx={{mr:'10px',float:'left'}}>
            <img  src='/media/comma.png'></img>
          </Box>
          Contacts
          </Typography>
          <Box sx={{pl:'36px'}}>
            
            <Grid container spacing={1} sx={{mt:0.5}}>
              <Grid item>
                    <PlaceIcon sx={[ { color: "#98857E", mx: 0.5 }]}/>
              </Grid>
              <Grid item sm  >
                <Typography  variant="subtitle1" >1, rue Eugène sylvain</Typography>
                <Typography  variant="subtitle1" > 83000 Toulon</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={{mt:0.5}}>
              <Grid item>
                    <DirectionsBusFilledIcon sx={[ { color: "#98857E", mx: 0.5 }]}/>
              </Grid>
              <Grid item sm  >
                <Typography  variant="subtitle1" >Bus : arrêt Bir Hakeim</Typography>
                <Typography  variant="subtitle1" >9 / 39 / 102 / 103 / 191</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1} sx={{mt:0.5}}>
                <Grid item>
                <IconButton
                    size='small'
                    href="tel:0422792926"
                    color='inherit' sx={[ { color: "#98857E", display: "flex" }, { "&:hover": {  color: "#000"}}]}>
                      <LocalPhoneIcon />
                  </IconButton>
                </Grid>
                <Grid item sm  sx={{alignItems: 'center',display:'flex'}}>
                  <Typography  variant="subtitle1" > 04 22 79 29 26 </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1} sx={{mt:0.5}}>
                <Grid item>
                <IconButton
                    size='small'
                    href="mailto:chrisferreira@live.fr"
                    color='inherit' sx={[ { color: "#98857E", display: "flex" }, { "&:hover": {  color: "#000"}}]}>
                      <EmailIcon />
                  </IconButton>
                </Grid>
                <Grid item sm  sx={{alignItems: 'center',display:'flex'}}>
                  <Typography  variant="subtitle1"  >  chrisferreira@live.fr </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={{mt:0.5}}>
                <Grid item>
                <IconButton
                    size='small'
                    onClick={()=>openLink("https://www.instagram.com/lpg.toulon/")}
                    color='inherit' sx={[ { color: "#98857E", display: "flex" }, { "&:hover": {  color: "#000"}}]}>
                      <InstagramIcon />
                  </IconButton>
                </Grid>
                <Grid item sm  sx={{alignItems: 'center',display:'flex'}}>
                  <Typography  variant="subtitle1" > lpg.toulon </Typography>
                </Grid>
              </Grid>

          </Box>
         
          </Box>
        </Grid>
        <Grid item lg={8} xs={12} sx={{ textAlign:{md:'right',xs:'center'},m:{md:'0px',xs:'3em'}}}>
         <Box sx={{borderRadius:'10px',overflow:'hidden',display:'flex'}}><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3632.6997933534853!2d5.940499!3d43.1207579!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c91ba09500cced%3A0x54634af095903d1e!2s1%20Rue%20Eug%C3%A8ne%20Silvain%2C%2083000%20Toulon%2C%20Fran%C3%A7a!5e1!3m2!1spt-PT!2spt!4v1700587269888!5m2!1spt-PT!2spt" width="100%" height="450" style={{border:0}} custom-allowfullscreen="" loading="lazy" custom-referrerpolicy="no-referrer-when-downgrade"></iframe></Box>
         
        </Grid>
      </Grid>
      
      </section>
      </div>

  );
}
