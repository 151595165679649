import "./CoachingSportif.scss";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import {useEffect, useState,useRef} from 'react';


export default function CoachingSportif() {
  var scrollTop = 0;
 

  const [maxScrollTop, setMaxScrollTop] = useState(11);

  const reveal2 = useRef(null);
  const reveal3 = useRef(null);
  const reveal4 = useRef(null);
  const reveal5 = useRef(null);
  useEffect(() => {
    const handleScroll = (event:any) => {
      scrollTop = Math.max(window.scrollY,scrollTop);
      setMaxScrollTop(scrollTop);
    };
    window.addEventListener('scroll', handleScroll);
    setMaxScrollTop(100);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getRectTop = (ref:any)=>{
    return ref?.current?.getBoundingClientRect().top  + window.scrollY -  document.documentElement.clientHeight + 100 };

  const  openLink =(link:string)=>{
    window.open(link,'_blank');
  }
  return ( 
    
    <Box sx={{width:'100%'}}>
      
     
      
   
      <section >
      <img style={{width:'100%',display:'block',marginTop:"140px"}} src='/media/bg_coaching_bottom.jpg'></img>
      <div className=" fe-coaching-section">
      <div className=" fe-container-fluid" >

    <Grid container spacing={2} sx={{mb:'60px'}}>
        <Grid ref={reveal2} className={(maxScrollTop > getRectTop(reveal2))?"fe-l-slide reveal":"fe-l-slide " } item md={12} xs={12} sx={{display:'inline-block',mb:'40px'}}>
          <Box sx={{p:{md:'0px',xs:'20px'}}}>
          <Typography sx={{fontStyle:'italic',mb:'1em'}} variant="h4" color="#fff" >
          <Box sx={{mr:'10px',float:'left'}}>
            <img  src='/media/comma_white.png'></img>
          </Box>
          Coaching Sportif
          </Typography>
          <Box sx={{pl:'36px'}}>
          <Typography  variant="subtitle1"  color="#fff" paragraph>À votre écoute pour construire une solution adaptée à vos besoins.  </Typography>
          <Typography  variant="subtitle1"  color="#fff" paragraph>Atteindre vos objectifs. </Typography>
          <Typography  variant="subtitle1"  color="#fff" paragraph>Accompagnement sur mesure. </Typography>
          <Typography  variant="subtitle1"  color="#fff" paragraph> Profiter de L'expérience sportive et de ses compétences de kinésithérapeute</Typography>
          <Typography  variant="subtitle1"  color="#fff" paragraph>Le plateau technique du cabinet saura répondre à vos attentes.</Typography>
          <Typography  variant="subtitle1"  color="#fff" paragraph>Séances de 30 min à 1h30, seul ou à plusieurs. Forfait de groupe et devis pour carnet de plusieurs séances sur demande</Typography>
          </Box>
          </Box>
          </Grid>
        <Grid item md={12} xs={12} sx={{textAlign:'center' ,mb:'40px'}}>
        <Button onClick={()=>openLink("https://www.doctolib.fr/masseur-kinesitherapeute/toulon/christopher-ferreira/booking?utm_campaign=website-button&amp;utm_source=christopher-ferreira-website-button&amp;utm_medium=referral&amp;utm_content=withoutpreview-blue-floating-bottom-right&amp;utm_term=christopher-ferreira")}  className={(maxScrollTop > getRectTop(reveal2))?"fe-l-slide reveal":"fe-l-slide " }  color="info" variant="outlined" size="large" sx={{mb:6}}   >
         Découvrez nos offres sur  <div><img style={{height:'22px',verticalAlign:'middle',width:'auto'}} src='https://pro.doctolib.fr/external_button/doctolib-white-transparent.png' alt='Doctolib'/></div> 
       </Button>
       
        </Grid>
        
        <Grid item lg={4} md={6} xs={12} sx={{justifyContent: 'center',display:'flex',mb:'2em' }} >
                   <div ref={reveal3} className={"fe-fade  d1 " + (maxScrollTop > getRectTop(reveal3)?"reveal":"" )}>
                    <div className="fe-photo fe-photo-1">
                      <div></div>
                    </div>
                  </div>
        </Grid>
        <Grid item lg={4} md={6} xs={12} sx={{justifyContent: 'center',display:'flex',mb:'2em' }}  >
                  <div ref={reveal4} className={"fe-fade  d2 " + (maxScrollTop > getRectTop(reveal4)?"reveal":"" )}>
                    <div  className="fe-photo fe-photo-2" >
                      <div></div>
                    </div>
                  </div>
        </Grid>
        <Grid id="section-contacts" item lg={4} md={12} xs={12} sx={{justifyContent: 'center',display:'flex',mb:'2em' }} >
                  <div ref={reveal5} className={"fe-fade  d3 " + (maxScrollTop > getRectTop(reveal5)?"reveal":"" )}>
                    <div className='fe-photo fe-photo-3'>
                      <div></div>
                    </div>
                  </div>
        </Grid>
      </Grid>
      </div>
      </div>
 
    </section>
    </Box>
   

  );
}
