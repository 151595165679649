import "./Lpg.scss";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import video from "../video/video6.mp4";
import {useEffect, useState,useRef} from 'react';
import Carousel from 'react-material-ui-carousel'
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import { CircularProgress, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

export default function LPG() {
  var scrollTop = 0;
  const [maxScrollTop, setMaxScrollTop] = useState(101);
 
  const theme = useTheme();
  const reveal6 = useRef(null);
  const reveal7 = useRef(null);
  const reveal8 = useRef(null);
  const reveal9 = useRef(null);
  const reveal10 = useRef(null);
  const reveal11 = useRef(null);
  const reveal12 = useRef(null);
  const reveal13 = useRef(null);
  const reveal14 = useRef(null);
  const reveal15 = useRef(null);

  const [open, setOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleScroll = (event:any) => {
      scrollTop = Math.max(window.scrollY,scrollTop);
      setMaxScrollTop(scrollTop);
      
    };
    window.addEventListener('scroll', handleScroll);
    setMaxScrollTop(100);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getRectTop = (ref:any)=>{
    return ref?.current?.getBoundingClientRect().top  + window.scrollY -  document.documentElement.clientHeight + 100 };

  const  openLink =(link:string)=>{
    window.open(link,'_blank');
  }
  return ( 
    <div className="fe-container-padtop fe-container-top-bg">
    <section  className="fe-container-fluid fe-section">
    <Grid container spacing={2}  >
        <Grid item md={12} xs={12} sx={{display:'inline-block',pr:{md:'100px',xs:'0px'}}}>
          <Box sx={{p:{md:'0px',xs:'20px'}}}>
          <Typography  ref={reveal6} className={(maxScrollTop > getRectTop(reveal6))?"fe-r-slide reveal":"fe-r-slide " } sx={{fontStyle:'italic',mb:'1.5em'}} variant="h4" color="#98857E" >
          <Box sx={{mr:'10px',float:'left'}}>
            <img  src='/media/comma.png'></img>
          </Box>
          LPG : endermologie® cellu M6 alliance
          </Typography>
          
          </Box>
        </Grid>
        
        <Grid  ref={reveal7} className={(maxScrollTop > getRectTop(reveal7))?"fe-r-slide reveal":"fe-r-slide " } item md={4} xs={12} sx={{justifyContent:'center',display:'flex'}}>
          <Box sx={{padding:{md:'0',xs:'3em'}}}>
          <img style={{width:'100%'}} src='/media/lpg1.jpg'></img>
          </Box>
         
        </Grid>
        <Grid   className={(maxScrollTop > getRectTop(reveal7))?"fe-l-slide reveal":"fe-l-slide " } item md={8} xs={12} sx={{justifyContent:{md:'left',xs:'center'},alignItems:'end',display:'flex',mb:{md:'0',xs:'4em'}}}>
          <Box className="fe-square-comma" sx={{left:{md:'-50px',xs:'inherit'},top:{md:'-60px',xs:'0px'}}}>
          <Typography  variant="h5" >C’est un engagement :  </Typography>
          </Box>
          
        </Grid>
        
        <Grid  ref={reveal8} className={(maxScrollTop > getRectTop(reveal8))?"fe-l-slide reveal":"fe-l-slide " } item md={6}  xs={12} mt={6}>
          <Paper elevation={2}  sx={{ minHeight: 200 ,textAlign:'center'}}>
              <Box className="fe-round-icon round-green">
                <Typography  variant="h6"  > POUR </Typography>
              </Box> 
              <Typography  variant="subtitle1" sx={{px:'2em',pb:'2em'}} >
              Une beauté qui nous ressemble. <b >NATURELLE</b>. <b>SAINE</b>. <b>DURABLE</b>. Une stratégie intelligente <b>SANS RISQUE, SANS EFFET SECONDAIRE</b>, ayant pour but de ralentir les effets inesthétiques du temps qui passe. 
              </Typography>
          </Paper>
        </Grid>
      
        <Grid className={(maxScrollTop > getRectTop(reveal8))?"fe-r-slide reveal":"fe-r-slide " }  item md={6}  xs={12}  mt={6}>
        <Paper elevation={2} sx={{ minHeight: 200 ,textAlign:'center'}}>
            <Box className="fe-round-icon round-red">
              
              <Typography  variant="h6"  > CONTRE </Typography>
            </Box> 
              <Typography  variant="subtitle1" sx={{px:'2em',pb:'2em'}}>
              Les excès des techniques agressives, non naturelles, aux résultats aléatoires et aux complications potentielles, une beauté stéréotypée, artificielle, une lutte insensée contre le temps qui passe.
              </Typography>
          </Paper>
         
        </Grid>
        <Grid item xs={12} sx={{my:4}}>
        <Divider><img  src='/media/comma.png'></img></Divider>
        </Grid>
        
       
      </Grid>
    </section>
   
    <section className="fe_lpg_section_2 " >
      <div className="fe-container-fluid">
    <Grid container spacing={2} >
        <Grid item md={6} xs={12} sx={{p:2}} >
          <div   ref={reveal9} className={(maxScrollTop > getRectTop(reveal9))?"fe-l-slide fe_lpg_face reveal":"fe_lpg_face fe-l-slide " } >
              <div className="fe_face_img"></div>
              <Box sx={{textAlign:'center',color:'#fff',fontStyle:'italic'}}>
              <Typography  variant="h6"  > POUR LE  </Typography>
              <Typography  variant="h4"  >VISAGE</Typography>
              </Box>
              <img  src='/media/lpg_img_2.jpg'></img>
          </div>
        </Grid>
        <Grid item   xs sx={{display:{md:'none',xs:'block'}}}>
        <Typography className={(maxScrollTop > getRectTop(reveal9))?"fe_text_white fe-r-slide reveal":" fe_text_white fe-r-slide " }  variant="subtitle1" sx={{px:'2em',textAlign:'center'}} >
        Agissant tel un véritable fitness de la peau, les <b>clapets motorisés</b> réveillent la synthèse naturelle des substances essentielles de la jeunesse. 
        Cette stimulation cellulaire, permet alors aux  <b>fibroblastes</b>  (cellules de la jeunesse) de relancer leur production de <b>collagène (fermeté)</b>, 
        d’<b>élastine (souplesse)</b> et d’<b>acide hyaluronique (volume & hydratation)</b>, naturellement présents dans notre peau 
        <Link href="#" onClick={()=>{openLink('https://www.lpgmedical.com/fr/wp-content/uploads/sites/5/2016/11/138444072417innocenzi_dermotime2003_vf.pdf')}}> <b>LA SAPIENZA (lpgmedical.com)</b></Link>
        </Typography>
             
        </Grid>
        <Grid   item md={6} xs={12} sx={{p:2}} >
          <Box className={(maxScrollTop > getRectTop(reveal9))?"fe-r-slide reveal":"fe-r-slide " }>
          <div  className="fe_lpg_body">
          <img  src='/media/lpg_img_1.jpg'></img>
          <Box sx={{textAlign:'center',color:'#fff',fontStyle:'italic'}}>
              <Typography  variant="h6"  > POUR LE  </Typography>
              <Typography  variant="h4"  >CORPS</Typography>
              </Box>
              <div className="fe_body_img"></div>
              
          </div>
          </Box>
         
        </Grid>
        <Grid item   xs sx={{display:{md:'none',xs:'block'}}}>
          <Typography className={(maxScrollTop > getRectTop(reveal10))?"fe_text_white fe-fe-r-slide reveal":"fe_text_white fe-fe-r-slide " } variant="subtitle1" sx={{px:'2em',textAlign:'center'}} >
          Agissant simultanément sur le déstockage des graisses rebelles et sur la qualité de peau (fermeté, aspect peau d’orange), 
          <Link href="#" onClick={()=>{openLink('https://www.lpgmedical.com/fr/wp-content/uploads/sites/5/2016/11/1385392862442ortonne2004.pdf')}}><b>cellulite </b></Link>
          le nouveau brevet (Rouleau et Clapet Motorisés couplés à une aspiration séquentielle) permet à la technologie corps une action
           directe sur les <b>adipocytes</b> présents dans notre hypoderme (cellules de la minceur), et sur les <b>fibroblastes</b> présents dans notre derme
            (cellules de la jeunesse). <b>Une prise en charge à 360°, mettant fin au dilemme : minceur ou fermeté ?</b>
        </Typography>
          </Grid>

        <Grid item  xs sx={{display:{md:'block',xs:'none'}}}>
        <Typography ref={reveal10} className={(maxScrollTop > getRectTop(reveal10))?"fe_text_white fe-l-slide reveal":"fe_text_white fe-l-slide " }  variant="subtitle1" sx={{px:'2em',textAlign:'center'}} >
        Agissant tel un véritable fitness de la peau, les <b>clapets motorisés</b> réveillent la synthèse naturelle des substances essentielles de la jeunesse. 
        Cette stimulation cellulaire, permet alors aux  <b>fibroblastes</b>  (cellules de la jeunesse) de relancer leur production de <b>collagène (fermeté)</b>, 
        d’<b>élastine (souplesse)</b> et d’<b>acide hyaluronique (volume & hydratation)</b>, naturellement présents dans notre peau
        <Link href="#" onClick={()=>{openLink('https://www.lpgmedical.com/fr/wp-content/uploads/sites/5/2016/11/138444072417innocenzi_dermotime2003_vf.pdf')}}>  <b>LA SAPIENZA (lpgmedical.com)</b></Link>
        </Typography>
             
        </Grid>

          <Divider className={(maxScrollTop > getRectTop(reveal10))?"fe-fade reveal":"fe-fade " }  sx={{borderColor:'#b69f91',display:{md:'block',xs:'none'}}} orientation="vertical" flexItem>
            
          </Divider>
          <Grid item  xs sx={{display:{md:'block',xs:'none'}}}>
          <Typography className={(maxScrollTop > getRectTop(reveal10))?"fe_text_white fe-r-slide reveal":"fe_text_white fe-r-slide " }  variant="subtitle1" sx={{px:'2em',textAlign:'center'}} >
          Agissant simultanément sur le déstockage des graisses rebelles et sur la qualité de peau (fermeté, aspect peau d’orange),  
          <Link href="#" onClick={()=>{openLink('https://www.lpgmedical.com/fr/wp-content/uploads/sites/5/2016/11/1385392862442ortonne2004.pdf')}} > <b>cellulite</b></Link>  le nouveau brevet (Rouleau et Clapet Motorisés couplés à une aspiration séquentielle) permet a la technologie corps une action
           directe sur les <b>adipocytes</b> présents dans notre hypoderme (cellules de la minceur), et sur les <b>fibroblastes</b> présents dans notre derme
            (cellules de la jeunesse). <b>Une prise en charge à 360°, mettant fin au dilemme : minceur ou fermeté ?</b>
        </Typography>
          </Grid>

          
          </Grid>
      
          </div>
    </section>
    
    <section  className="fe-container-fluid" style={{display:'block'}}>
     
    <Grid container spacing={2} my={3} sx={{alignItems:'center',flexDirection:'column'}}>
    
      <Divider sx={{width:'100%'}} ><img  src='/media/comma.png'></img></Divider>

      <Grid item lg={8} xs={12} >
      <Typography  ref={reveal11} className={(maxScrollTop > getRectTop(reveal11))?"fe-fade reveal":"fe-fade" }    variant="h6" sx={{px:'2em',textAlign:'center'}} >
        <b style={{fontStyle:'italic'}}>C’est aussi relancer l’activité cellulaire endormie au cœur de notre peau pour lutter contre toutes manifestations inesthétiques (rides, peau relâchée, rondeurs rebelles, peau d’orange…).</b>
      </Typography>
      </Grid>
      <Grid item  xs={12} mt={2} style={{width:'100%'}}>
      
        <Box ref={reveal12} sx={{display: 'flex', justifyContent: 'center'}} className={(maxScrollTop > getRectTop(reveal12))?"fe-fade reveal":"fe-fade "  } >
      <Carousel
             
              indicatorIconButtonProps={{ style: {color: '#776964' }}} 
              activeIndicatorIconButtonProps={{style: {  color: '#ffdfbf' }}} 
              sx={{width:'100%',maxWidth:'600px'}} >
              
              {[...Array(10)].map((x, i) =>
                <img width={'100%'} key={i}   src={'/media/ec'+(i+1)+'.jpg'}></img>
              )}
             
              </Carousel>
             </Box>
             </Grid>
      </Grid>  
      <Divider sx={{width:'100%'}} ><img  src='/media/comma.png'></img></Divider>
      <Grid container spacing={2} my={5}>
      <Grid item lg={8}   xs={12} ref={reveal13} className={(maxScrollTop > getRectTop(reveal13))?"fe-l-slide reveal":"fe-l-slide" }  >
        <Typography   variant="subtitle1"  >
        L'expertise tissulaire, articulaire et musculaire, grâce à des protocoles de traitements spécifiques, les technologies LPG sont intégrées dans différents domaines d'intervention:
        <Link href="#" onClick={()=>{openLink('https://www.lpgmedical.com/fr/wp-content/uploads/sites/5/2016/11/1385472193940leduc_2001.pdf')}}>  <b>Assemblage Therapeutique</b></Link> , 
        <Link href="#" onClick={()=>{openLink('https://www.lpgmedical.com/fr/wp-content/uploads/sites/5/2016/11/1385472474706leduc_oct2000.pdf')}}>  <b>Technique LPG et Drainage Lymphatique</b></Link> , 
        <Link href="#" onClick={()=>{openLink('https://fr.wikipedia.org/wiki/Drainage_lymphatique_manuel')}}>  <b>Drainage lymphatique manuel</b></Link> , 
        <Link href="#" onClick={()=>{openLink('https://fr.wikipedia.org/wiki/Syst%C3%A8me_lymphatique')}}>  <b>Système lymphatique</b></Link> , 

         rhumatologie, orthopédie, sénologie, brûlologie, maxillo-faciale, uro-gynecologie et sport.

        </Typography>
        <br/>
        <Typography  paragraph    variant="subtitle1"  >
        Alors que la prévention, la recherche et les traitements contre le cancer du sein font d’énormes progrès, la prise en charge post-opératoire ou post-traitement en est encore à ses prémices. Pourtant, le temps de la reconstruction physique et psychologique est fondamental pour un retour à la vie active et à une féminité apaisée. C’est l’engagement de LPG® : être un allié de poids auprès des femmes atteintes d’un cancer du sein 
        <Link href="#" onClick={()=>{openLink('https://www.lpgmedical.com/fr/wp-content/uploads/sites/5/2023/03/5-Med.-J.-Cairo-Univ_2021.pdf')}}>  <b>Endermologie versus Low Level Laser Therapy on Post Mastectomy Lymphedema  (lpgmedical.com)</b></Link> , 
        <Link href="#" onClick={()=>{openLink('https://www.lpgmedical.com/fr/wp-content/uploads/sites/5/2016/11/1385472474706leduc_oct2000.pdf')}}>  <b>Effects of mechanical stimulation on mastectomy scars within 2 months of surgery: A single-center, single-blinded, randomized controlled trial (lpgmedical.com)</b></Link> , 
        <Link href="#" onClick={()=>{openLink('https://www.lpgmedical.com/fr/wp-content/uploads/sites/5/2016/09/1472134471266endermologie_and_post_mastectomy_lymphedema.pdf')}}>  <b>Effectiveness of Endermologie Technique in Post-Mastectomy Lymphedema </b></Link> , 
        pour les accompagner au mieux dans leur reconstruction.
        </Typography>
        <br/>
        <Typography     variant="subtitle1"  >
        La machine LPG fait aussi parler d’elle dans les magazines 
        <br/><Link href="#" onClick={()=>{openLink('https://www.femmeactuelle.fr/beaute/soins-beaute/challenge-be-yourself-lpg-et-femme-actuelle-les-hanches-ont-ete-remodelees-laspect-de-ma-peau-a-vraiment-change-josepha-24-ans-2123006')}}>  <b>Les hanches ont été remodelées, l’aspect de ma peau a vraiment changé  (www.femmeactuelle.fr)</b></Link> 
        <br/><Link href="#" onClick={()=>{openLink('https://www.femmeactuelle.fr/minceur/astuces-minceur/cellu-m6-endermologie-avec-lpg-2020336')}}>  <b>Cellu m6 endermologie avec lpg (www.femmeactuelle.fr)</b></Link> 
        <br/><Link href="#" onClick={()=>{openLink('https://www.marieclaire.fr/,l-endermologie-the-technique-contre-la-cellulite,20149,934.asp')}}>  <b>L'endermologie the technique contre la cellulite (www.marieclaire.fr)</b></Link> 
        <br/><Link href="#" onClick={()=>{openLink('https://www.marieclaire.fr/,rajeunir-avec-les-nouvelles-techniques,701161.asp')}}>  <b>Rajeunir avec les nouvelles techniques (www.marieclaire.fr)</b></Link> 
        <br/> <br/>
        </Typography>
      </Grid>
      <Grid item lg={4}  xs={12} sx={{textAlign:{xs:'center'}}} px={2}>
      <div   ref={reveal15} className={(maxScrollTop > getRectTop(reveal15))?"fe-r-slide reveal":"fe-r-slide" }>
            <div className="fe-photo-back" >
              <img  style={{ maxHeight: "600px" }} src='/media/mesurement.jpg' ></img>
            </div>
          </div>
      </Grid>
      </Grid>
      <Divider sx={{width:'100%'}} ><img  src='/media/comma.png'></img></Divider>
      <Grid container spacing={2} sx={{alignItems:'center',mt:3,flexDirection:'column'}}>
      <Grid ref={reveal14} item xs={12} >
      <Button  className={(maxScrollTop > getRectTop(reveal14))?"fe-fade reveal":"fe-fade" } color="primary" size="large" sx={{mb:1}} onClick={handleClickOpen} variant="outlined"  >
          Decouvrir les soins CORPS et VISAGE LPG proposés
       </Button>  
       
       </Grid>
      <Grid item  xs={12} >
      <Button onClick={()=>openLink("https://www.doctolib.fr/masseur-kinesitherapeute/toulon/christopher-ferreira/booking?utm_campaign=website-button&amp;utm_source=christopher-ferreira-website-button&amp;utm_medium=referral&amp;utm_content=withoutpreview-blue-floating-bottom-right&amp;utm_term=christopher-ferreira")}  id="section-renata" className={(maxScrollTop > getRectTop(reveal14))?"fe-fade reveal":"fe-fade " }  color="primary" variant="contained" size="large" sx={{mb:6,color:'#fff'}}   >
         Prenez rendez-vous sur  <img style={{height:'19px',verticalAlign:'middle',width:'auto'}} src='https://pro.doctolib.fr/external_button/doctolib-white-transparent.png' alt='Doctolib'/>
       </Button>
      </Grid>
    </Grid>

   
    </section>

    <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        maxWidth={'md'}
        fullWidth={true}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
        Les soins CORPS et VISAGE LPG proposés
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        
        <DialogContent dividers>
          <DialogContentText>
          <Grid container spacing={2} >
    

           <Grid item xs >
          <List dense>
            <ListItem>
              <ListItemText primary="Les soins zones, 1 à 8 zones	"  />
              <ListItemAvatar >
              <Chip  label="10’ à 40’" variant="outlined" />
              </ListItemAvatar>
            </ListItem>
            <ListItem>
              <ListItemText primary="Anti cellulite"  />
              <ListItemAvatar sx={{minWidth:0}}>
              <Chip  label="40’" variant="outlined" />
              </ListItemAvatar>
            </ListItem>
            <ListItem>
              <ListItemText primary="Jeune maman"  />
              <ListItemAvatar sx={{minWidth:0}}>
              <Chip  label="30’" variant="outlined"/>
              </ListItemAvatar>
            </ListItem>
            <ListItem>
              <ListItemText primary="Endermopuncture"  />
              <ListItemAvatar sx={{minWidth:0}}>
              <Chip  label="50’" variant="outlined"/>
              </ListItemAvatar>
            </ListItem>
            <ListItem>
              <ListItemText primary="Cicatrices et brulures"  />
              <ListItemAvatar sx={{minWidth:0}}>
              <Chip  label="personnalisé" variant="outlined" />
              </ListItemAvatar>
            </ListItem>
            <Divider textAlign="left" sx={{color:'#dbc9ac',fontStyle:'italic'}}>Les soins classiques </Divider>
            <ListItem>
              <ListItemText primary="Eclat "  />
              <ListItemAvatar sx={{minWidth:0}}>
              <Chip  label="20'" variant="outlined" />
              </ListItemAvatar>
            </ListItem>
            <ListItem>
              <ListItemText primary="Decolleté et buste  "  />
              <ListItemAvatar sx={{minWidth:0}}>
              <Chip  label="30'" variant="outlined" />
              </ListItemAvatar>
            </ListItem>
            <ListItem>
              <ListItemText primary="Anti âge fermeté  "  />
              <ListItemAvatar sx={{minWidth:0}}>
              <Chip  label="35'" variant="outlined"/>
              </ListItemAvatar>
            </ListItem>
            <ListItem>
              <ListItemText primary="Detox "  />
              <ListItemAvatar sx={{minWidth:0}}>
              <Chip  label="25'" variant="outlined" />
              </ListItemAvatar>
            </ListItem>
            <ListItem>
              <ListItemText primary="Anti âge repulpant  "  />
              <ListItemAvatar sx={{minWidth:0}}>
              <Chip  label="35'" variant="outlined" />
              </ListItemAvatar>
            </ListItem>
            <ListItem>
              <ListItemText primary="Anti âge affinant  "  />
              <ListItemAvatar sx={{minWidth:0}}>
              <Chip  label="35'" variant="outlined" />
              </ListItemAvatar>
            </ListItem>
            <Divider textAlign="left" sx={{color:'#dbc9ac',fontStyle:'italic'}}>Les soins signatures  </Divider>
            <ListItem>
              <ListItemText primary="Hydratation booster "  />
              <ListItemAvatar sx={{minWidth:0}}>
              <Chip  label="50'" variant="outlined" />
              </ListItemAvatar>
            </ListItem>
            <ListItem>
              <ListItemText primary="Rénovateur anti âge "  />
              <ListItemAvatar sx={{minWidth:0}}>
              <Chip  label="40'" variant="outlined" />
              </ListItemAvatar>
            </ListItem>
            <ListItem>
              <ListItemText primary="Sublime regard et Lèvres  "  />
              <ListItemAvatar sx={{minWidth:0}}>
              <Chip  label="40'" variant="outlined"/>
              </ListItemAvatar>
            </ListItem>
            <ListItem>
              <ListItemText primary="Peau neuve   "  />
              <ListItemAvatar sx={{minWidth:0}}>
              <Chip  label="75'" variant="outlined"/>
              </ListItemAvatar>
            </ListItem>
            <ListItem>
              <ListItemText primary="Régénération cellulaire  "  />
              <ListItemAvatar sx={{minWidth:0}}>
              <Chip  label="75'"  variant="outlined" />
              </ListItemAvatar>
            </ListItem>
          </List>
          </Grid>
          <Grid item  width={'190px'} sx={{display:{md:'block',xs:'none'}}}>
          <img style={{maxWidth:'100%'}} src='/media/image16.jpg'></img>
          </Grid>
          </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        
          <Button onClick={handleClose} autoFocus>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </div>

  );
}
