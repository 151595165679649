import "./Kine.scss";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import {useEffect, useState,useRef} from 'react';

export default function Kine() {
  var scrollTop = 0;
  const [maxScrollTop, setMaxScrollTop] = useState(101);

  const reveal1 = useRef(null);

  useEffect(() => {
    const handleScroll = (event:any) => {
      scrollTop = Math.max(window.scrollY,scrollTop);
      setMaxScrollTop(scrollTop);
    };
    window.addEventListener('scroll', handleScroll);
    setMaxScrollTop(100);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getRectTop = (ref:any)=>{
    return ref?.current?.getBoundingClientRect().top  + window.scrollY -  document.documentElement.clientHeight + 100 };

  const  openLink =(link:string)=>{
    window.open(link,'_blank');
  }
  return ( 
    
    <div className="fe-container-padtop">
        <section ref={reveal1}  className="fe-container-fluid fe-section">
      <Grid container spacing={2}>
        <Grid  className={(maxScrollTop > getRectTop(reveal1))?"fe-l-slide reveal":"fe-l-slide " }  item md={8} xs={12} sx={{display:'inline-block',pr:{md:'100px',xs:'0px'}}}>
          <Box sx={{p:{md:'0px',xs:'20px'}}}>
          <Typography sx={{fontStyle:'italic',mb:'1em'}} variant="h4" color="#98857E" >
          <Box sx={{mr:'10px',float:'left'}}>
            <img  src='/media/comma.png'></img>
          </Box>
          Kiné et professionnels de santé
          </Typography>
          <Box sx={{pl:'36px'}}>
          <Typography  variant="subtitle1"  paragraph>Basée sur la rééducation fonctionnelle et manuelle, la kinésithérapie interviendra pour la remise en forme et le bien être des patients. Mais pas seulement ! </Typography>
          <Typography  variant="subtitle1"  paragraph>En effet, votre cabinet prendra en charge tous les bobos de la vie quotidienne, sportive et professionnelles mais également les pathologies plus chroniques.  </Typography>
          <Typography  variant="h6"  paragraph><b>Mal du dos : mal du siècle ? </b></Typography>
          <Typography  variant="subtitle1"  paragraph> Spécialisé dans l’école du dos, c’est avec un ostéopathe présent à mi-temps au cabinet que nous tenterons de répondre au maximum à vos attentes. Cela passe par une bonne compréhension de la survenue de la douleur mais aussi par des conseils pratiques professionnels ou sportifs pour éliminer la douleur. Et enfin, par une rééducation globale afin que les maux ne reviennent plus. </Typography>
          <Typography  variant="subtitle1"  paragraph>A noter que 3 infirmières ont également élu domicile au cabinet, pour une prise en charge encore plus complète. </Typography>
          <Button onClick={()=>openLink("https://www.doctolib.fr/masseur-kinesitherapeute/toulon/christopher-ferreira/booking?utm_campaign=website-button&amp;utm_source=christopher-ferreira-website-button&amp;utm_medium=referral&amp;utm_content=withoutpreview-blue-floating-bottom-right&amp;utm_term=christopher-ferreira")} color="primary" variant="contained" size="small" sx={{my:2,color:'#fff'}}   >
          Prenez Rendez-vous sur  <div><img style={{height:'22px',verticalAlign:'middle',width:'auto'}} src='https://pro.doctolib.fr/external_button/doctolib-white-transparent.png' alt='Doctolib'/></div> 
        </Button>
          </Box>
         
          </Box>
        </Grid>
        <Grid item md={4} xs={12} sx={{textAlign:{md:'right',xs:'center'},m:{md:'0px',xs:'3em'}}}>
          <div    className={(maxScrollTop > getRectTop(reveal1))?"fe-r-slide reveal":"fe-r-slide " } >
            <div className="fe-photo-back">
              <img  style={{ maxHeight: "400px" }} src='/media/image4.jpeg' ></img>
            </div>
          </div>
        </Grid>
      </Grid>
    </section>
    <section  >
    <div><img style={{width:'100%',display:'block'}} src='/media/bg_top.jpg'></img></div>
      <div id="section-coaching" className="fe-marquee-image"></div>
    </section>

    </div>
   

  );
}
