import axios from 'axios';

export default class UserService {

   
    login = (credentials:any) =>{
         return  axios.post(`${process.env.REACT_APP_AUTH_URL}/login`, credentials).then( (response:any)=> {
            const { accessToken, refreshToken } = response.data;
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('token', accessToken);
        });
    }

    logout = (refreshToken:any)=>{
       
        return axios.delete(`${process.env.REACT_APP_AUTH_URL}/logout`,{
            params: { token: refreshToken },
          }).then( ()=> {
            localStorage.setItem('refreshToken', "");
            localStorage.setItem('token', "");
            
        });
        
    }

 
};

 