import "./MainPage.scss";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import video from "../video/kine.mp4";
import { useEffect, useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CircularProgress, useMediaQuery } from "@mui/material";
import Rating from '@mui/material/Rating';
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink} from "react-router-dom";

import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import GoogleIcon from '@mui/icons-material/Google';
import Avatar from "@mui/material/Avatar";

import api from '../authentication/interceptor.context';

export default function MainPage() {
  var scrollTop = 0;

  const [isLoading, setIsLoading] = useState(true);

  const [maxScrollTop, setMaxScrollTop] = useState(101);

  const reveal1 = useRef(null);
  const reveal2 = useRef(null);
  const reveal3 = useRef(null);
  const reveal4 = useRef(null);
  const reveal5 = useRef(null);
  const reveal6 = useRef(null);
  const reveal7 = useRef(null);
  const reveal8 = useRef(null);
  const reveal9 = useRef(null);
  const reveal10 = useRef(null);
  const reveal11 = useRef(null);
  const reveal12 = useRef(null);
  const reveal13 = useRef(null);
  const reveal14 = useRef(null);
  const reveal15 = useRef(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const issmallscreen = useMediaQuery(theme.breakpoints.down("sm"));

 

  const [comments, setComments] = useState([] as any[]);

    useEffect(() => {
        api.get('/comments')
          .then(function (response) {
            setComments(response.data.map((a:any)=>{
              a.comment1 = a.comment.substring(0,100);
              a.comment2 = a.comment.substring(100);
              a.expanded = false;
              return a;
             }));
          })
          .catch(function (error:any) {
            console.log(error);
            setComments( [
              {name: "ObéiSciences Doriane GRANGER",comment1:" Violaine est une professionnelle passionnée. Elle m'a expliqué en détail les effets de la cure de drainage Renata França et de ses bienfaits.",comment2:" Ses connaissances dans le domaine du corps humain et de son fonctionnement m'ont beaucoup apporté. Les bienfaits ne sont pas seulement physique. Je me sens de mieux en mieux car j'apprends grâce à ses conseils à prendre soin de moi. Elle est chaleureuse et à l'écoute. Encore un grand merci Violaine pour ta douceur.",color:"#3C8699",date:"01/11/2023",rating:5,expanded:false},
              {name: "Sarah Milosavljevic",comment1:" Je suis très satisfaite de mes massages renata avec Violaine. Elle est très humaine, à l’écoute du corps et de l’esprit.",comment2:"Elle a vraiment des mains de fées, je vois une différence réelle sur mon ventre et mes jambes ! Je la recommande ! Foncez les yeux fermés!",color:"#98857E",date:"22/10/2023",rating:5,expanded:false},
              {name: "Claire Filiaggi",comment1:"Violaine est accueillante et très professionnelle. Le drainage Renata Franca juste magique! Ça vaut vraiment le coup de se faire ce plaisir.",comment2:"",color:"#BCA47E",date:"17/10/2023",rating:5,expanded:false},
              {name: "Sylvie Picard",comment1:" Je me sens incroyablement plus sûre de moi physiquement. Je ne mets plus d'anti-cernes . Adieu mes valises sous les yeux et mon triple menton est ,  mais tellement diminué.",comment2:"Je suis vraiment très satisfaite de mes soins .",color:"#999",date:"15/10/2023",rating:5,expanded:false},
              {name: "Marion Di Napoli",comment1:"Après 3 séances de drainage frança renata, les résultats sont bien là ! J'ai \"dégonflé\", je me sens plus légère, l'aspect peau d'orange a nettement diminué.",comment2:" J'étais déjà dans une démarche de mieux manger et faire du sport, les séances ont permis a mon corps de \"passer les palliers plus facilement\" et de ne pas reprendre. Un vrai complément efficace. Violaine est douce, agréable. Elle explique bien les choses, ça se voit qu'elle connait bien le fonctionnement du corps. Encore merci Violaine ! Si c'était à refaire, je l'aurai fait avant !",color:"#415D66",date:"05/11/2023",rating:5,expanded:false}
            ]);
          });
      }, []);

 

  const handleChangeExpanded = (index: number)=>{
    comments[index].expanded = true;
    setComments([...comments]);
  }

  const getRectTop = (ref: any) => {
    return (
      ref?.current?.getBoundingClientRect().top +
      window.scrollY -
      document.documentElement.clientHeight +
      100
    );
  };

  useEffect(() => {
    const handleScroll = (event: any) => {
      scrollTop = Math.max(window.scrollY, scrollTop);
      setMaxScrollTop(scrollTop);
    };
    window.addEventListener("scroll", handleScroll);
    setMaxScrollTop(100);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const goToSection = (section: string) => {
    let element = document.getElementById("section-" + section);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const openLink = (link: string) => {
    window.open(link, "_blank");
  };
  
  return (
    <Box sx={{ width: "100%" }}>
      <div className='fe-background-video '>
        <div className='fe-top-video '></div>
        {isLoading && (
          <div className='fe-progress-video '>
            <CircularProgress />
          </div>
        )}
        <video
          autoPlay={true}
          muted
          loop
          preload={"auto"}
          src={video}
          onLoadedData={() => setIsLoading(false)}
        ></video>

        <div className='fe_more' onClick={() => goToSection("main")}>
          <Typography
            sx={{ fontStyle: "italic", lineHeight: "1", mt: "20px" }}
            variant='subtitle1'>
            En savoir plus
          </Typography>

          <KeyboardArrowDownIcon
            sx={{ mt: "-5px" }}
            fontSize='large'
          ></KeyboardArrowDownIcon>
        </div>
      </div>
      <section id='section-main' className=' fe-section '>
        <div
          className=' fe-container-fluid'
          style={{ justifyContent: "center" }}
        >
          <Typography
          ref={reveal10}
          className={
            maxScrollTop > getRectTop(reveal10)
              ? "fe-l-slide reveal"
              : "fe-l-slide "
          }
            sx={{ fontStyle: "italic", mb: "1em", textAlign: "center" }}
            variant='h5'
          >
            <Box sx={{ mr: "10px", float: "left" }}>
              <img src='/media/comma.png'></img>
            </Box>
            Vous avez envie d’une vraie transformation et prendre soin de vous ?
            <br />
            <b style={{ color: "#9E8A82" }}>
              {" "}
              Faites peau neuve dans votre cabinet kinesthétique
            </b>
          </Typography>
        </div>
        <div
          className=' fe-container-fluid'
          style={{ justifyContent: "center" }}
        >
          <Typography
          ref={reveal11}
          className={
            maxScrollTop > getRectTop(reveal11)
              ? "fe-r-slide reveal"
              : "fe-r-slide "
          }
            sx={{ fontStyle: "italic", mb: "1em", textAlign: "center" }}
            variant='h5'
          >
            <Box sx={{ mr: "10px", float: "left" }}>
              <img src='/media/comma.png'></img>
            </Box>
            Vous en avez marre d’avoir un teint terne et une peau fatiguée ?
            <br />
          </Typography>
        </div>
        <div
          className=' fe-container-fluid'
          style={{ justifyContent: "center" }}
        >
          <Typography
          ref={reveal12}
          className={
            maxScrollTop > getRectTop(reveal12)
              ? "fe-l-slide reveal"
              : "fe-l-slide "
          }
            sx={{ fontStyle: "italic", mb: "1em", textAlign: "center" }}
            variant='h5'
          >
            <Box sx={{ mr: "10px", float: "left" }}>
              <img src='/media/comma.png'></img>
            </Box>
            Vous avez envie de vous sentir réellement mieux dans votre corps ?{" "}
            <br />
            <b style={{ color: "#9E8A82" }}>
              {" "}
              Dites non à la cellulite et à la peau d’orange, soignez votre
              visage !
            </b>
          </Typography>
        </div>
       
        <div
          className=' fe-container-fluid'
          style={{ justifyContent: "center" }}
        >
          <Grid container spacing={3} mx="2em">
            <Grid
              ref={reveal1}
              className={
                maxScrollTop > getRectTop(reveal1)
                  ? "fe-l-slide reveal"
                  : "fe-l-slide "
              }
              item
              md={6}
              xs={12}
              mt={6}
            >
              <Paper elevation={2} sx={{ minHeight: 200, textAlign: "center" }}>
                <Box className='fe-square-title'>
                  <Typography variant='h6'> VOTRE PROBLÉMATIQUE</Typography>
                </Box>
                <ul className='fe-list'>
                  <li>Cellulite et Graisses résistantes</li>
                  <li>Rétention d'eau</li>
                  <li>Surcharge pondérale</li>
                  <li>Ménopause</li>
                  <li>Circulation veineuse et lymphatique</li>
                  <li>Prise de poids</li>
                  <li>Perte de fermeté</li>
                  <li>Affaissement de l'ovale du visage</li>
                  <li>Teint terne</li>
                  <li>Rides et Ridules</li>
                </ul>
              </Paper>
            </Grid>

            <Grid
              className={
                maxScrollTop > getRectTop(reveal1)
                  ? "fe-r-slide reveal"
                  : "fe-r-slide "
              }
              item
              md={6}
              xs={12}
              mt={6}
            >
              <Paper elevation={2} sx={{ minHeight: 200, textAlign: "center" }}>
                <Box className='fe-square-title left'>
                  <Typography variant='h6'>NOS ENGAGEMENTS </Typography>
                </Box>
                <ul className='fe-list'>
                  <li>Un Bilan minutieux </li>
                  <li>Des Conseils avisés </li>
                  <li>Des professionnels à l’écoute</li>
                  <li>Un Coaching personnalisé</li>
                  <li>Une technique 100 % naturelle</li>
                  <li>Une expertise unique</li>
                  <li>Des résultats garantis</li>
                </ul>
              </Paper>
            </Grid>
            </Grid>
          </div>

          </section>
        <section className=' fe-section fe-section-abtract'>
        <div
          className=' fe-container-fluid'
          style={{ justifyContent: "center" }}
        >
          <Grid container spacing={3} mx="2em">
            <Grid
              ref={reveal2}
              className={
                maxScrollTop > getRectTop(reveal2)
                  ? "fe-fade reveal"
                  : "fe-fade "
              }
              item
              md={12}
            >
              <Typography my='1em' variant='body1'>
                Le drainage lymphatique est une technique non douloureuse,
                visant à stimuler la circulation de la lymphe dans le corps,
                éliminer les déchets et toxines du corps et participant
                activement au bien-être. Grace à cette action, le drainage
                lymphatique agit également tant sur une cellulite aqueuse qu’une
                cellulite fibreuse.
              </Typography>
              <Typography my='1em' variant='body1'>
                Le drainage lymphatique peut aussi bien être utilisé à des fins
                thérapeutiques qu’esthétiques. Les bénéfices sont nombreux dans
                différents domaines, lymphœdème, cellulite, peau d’orange,
                jambes lourdes, insuffisance veineuse, cicatrices, rides,
                diminution de la douleur mais aussi dans le bien être.
              </Typography>
              <Typography my='1em' variant='body1'>
                En lien direct avec le drainage lymphatique, le LPG est une
                machine efficace pour traiter la cellulite en profondeur, de
                manière naturelle, sans avoir à faire à la chirurgie. Le LPG
                utilise un massage drainant de type « palper rouler »
                directement sur la cellulite et ce, de manière indolore. Le LPG
                utilise un matériel breveté avec un système de rouleaux et
                d’aspiration séquentielle permettant de combattre la cellulite
                dans les couches les plus profondes de la peau. Grace à cette
                machine, LPG réinvente le « palper rouler » pour le rendre
                encore plus efficace.
              </Typography>
              <Typography my='1em' variant='body1'>
                Qui dit massage dit bien être, et outre cette technique de «
                palper rouler », le drainage lymphatique Renata França en
                possède également tous les atouts. <br />
                La méthode drainage lymphatique Renata França cumule plusieurs
                techniques de drainage et compte sur des pressions fermes et un
                rythme accéléré. Le résultat est un corps moins gonflé et galbé
                avec un métabolisme accéléré, une immunité renforcée et une
                sensation de bien-être.{" "}
              </Typography>
              <Typography my='1em' variant='body1'>
                Maman, jeune maman, maman presque parfaite, enceinte, mère
                d’alors, soyez mère veilleuse !! Ce message s’adresse aussi à
                vous… N’attendez plus pour prendre soin de vous, le bien être
                n’attend pas !
              </Typography>
            </Grid>
            </Grid>
          </div>

          </section>
        <section className=' fe-section '>
        <div
          className=' fe-container-fluid'
          style={{ justifyContent: "center" }}
        >
          <Grid container spacing={3}>
            <Grid ref={reveal3} className={   maxScrollTop > getRectTop(reveal3) ? "fe-fade reveal": "fe-fade " } sx={{mb:'1em'}} item  xs={12} sm={12} lg={6} xl={4}>
              <RouterLink className="fe-box-menu" to="/LPG">
               <div className="fe-box-menu-image "><img src="/media/image10.jpeg"/></div>
               <div className="fe-box-menu-text">  LPG </div>
              </RouterLink>
            </Grid>
            <Grid ref={reveal4} className={   maxScrollTop > getRectTop(reveal4) ? "fe-fade reveal": "fe-fade " }  sx={{mb:'1em'}} item  xs={12} sm={12} lg={6} xl={4}>
              <RouterLink className="fe-box-menu" to="/RenataFranca">
               <div className="fe-box-menu-image "><img src="/media/franca3.jpg"/></div>
               <div className="fe-box-menu-text">  FRANÇA RENATA </div>
              </RouterLink>
            </Grid>
            <Grid ref={reveal5} className={   maxScrollTop > getRectTop(reveal5) ? "fe-fade reveal": "fe-fade " } sx={{mb:'1em'}} item  xs={12} sm={12} lg={6} xl={4}>
              <RouterLink className="fe-box-menu" to="/Kine">
               <div className="fe-box-menu-image "><img src="/media/kine.jpg"/></div>
               <div className="fe-box-menu-text">  KINÉ </div>
              </RouterLink>
            </Grid>
            <Grid ref={reveal6} className={   maxScrollTop > getRectTop(reveal6) ? "fe-fade reveal": "fe-fade " } sx={{mb:'1em'}} item  xs={12} sm={12} lg={6} xl={4}>
              <RouterLink className="fe-box-menu" to="/CoachingSportif">
               <div className="fe-box-menu-image "><img src="/media/sport.jpg"/></div>
               <div className="fe-box-menu-text">  COACHING SPORTIF </div>
              </RouterLink>
            </Grid>
            <Grid ref={reveal7} className={   maxScrollTop > getRectTop(reveal7) ? "fe-fade reveal": "fe-fade " } sx={{mb:'1em'}} item   xs={12} sm={12} lg={6} xl={4}>
              <RouterLink className="fe-box-menu" to="/Pressotherapie">
               <div className="fe-box-menu-image "><img src="/media/pressotherapie5.jpg"/></div>
               <div className="fe-box-menu-text"> PRESSOTHÉRAPIE</div>
              </RouterLink>
            </Grid>
            <Grid ref={reveal8} className={   maxScrollTop > getRectTop(reveal8) ? "fe-fade reveal": "fe-fade " } sx={{mb:'1em'}} item  xs={12} sm={12} lg={6} xl={4}>
              <RouterLink className="fe-box-menu" to="/PeriNatalite">
               <div className="fe-box-menu-image "><img src="/media/natality.jpg"/></div>
               <div className="fe-box-menu-text">  PERINATALITE </div>
              </RouterLink>
            </Grid>

            <Grid ref={reveal9} className={   maxScrollTop > getRectTop(reveal9) ? "fe-fade reveal": "fe-fade " } sx={{mb:{sm:0,xl:'1em'}}} item  xs={12} sm={12} lg={6} xl>
              <RouterLink className="fe-box-menu" to="/Info">
              
               <div className="fe-box-menu-text-small"> <span> QUI SOMME NOUS?</span> </div>
              </RouterLink>
            </Grid>
            <Grid  className={   maxScrollTop > getRectTop(reveal9) ? "fe-fade reveal": "fe-fade " } sx={{mb:{sm:0,xl:'1em'}}} item  xs={12}  sm={12} lg={6} xl>
              <RouterLink className="fe-box-menu" to="/Tarif">
             
               <div className="fe-box-menu-text-small">  <span>NOS TARIFS </span></div>
              </RouterLink>
            </Grid>
            <Grid  className={   maxScrollTop > getRectTop(reveal9) ? "fe-fade reveal": "fe-fade " } sx={{mb:{sm:0,xl:'1em'}}} item xs={12}  sm={12} lg={6} xl>
              <RouterLink className="fe-box-menu" to="/Faq">
             
               <div className="fe-box-menu-text-small"> <span>QUESTIONS FRÉQUENTES </span></div>
              </RouterLink>
            </Grid>
            <Grid  className={   maxScrollTop > getRectTop(reveal9) ? "fe-fade reveal": "fe-fade " } sx={{mb:{sm:0,xl:'1em'}}} item  xs={12}  sm={12} lg={6} xl>
              <RouterLink className="fe-box-menu" to="/Glossaire">
             
               <div className="fe-box-menu-text-small"><span>GLOSSAIRE</span>  </div>
              </RouterLink>
            </Grid>
            <Grid className={   maxScrollTop > getRectTop(reveal9) ? "fe-fade reveal": "fe-fade " } sx={{mb:{sm:0,xl:'1em'}}} item  xs={12}  sm={12} lg={6} xl>
              <RouterLink className="fe-box-menu" to="/Contacts">
               <div className="fe-box-menu-text-small"><span>Contacts</span>  </div>
              </RouterLink>
            </Grid>
            </Grid>
          </div>

          </section>
        <section className=' fe-section fe-section-toulon'>
        <div
          className=' fe-container-fluid'
          style={{ justifyContent: "center" }}
        >
          <Grid container spacing={3} mx="2em">
            <Grid  ref={reveal13} className={maxScrollTop > getRectTop(reveal13) ? "fe-fade reveal"   : "fe-fade " } item  md={12} >
              <Typography my='1em' textAlign="center" variant='body1'>
              C’est dans ce quartier très passant de Toulon où nous vous accueillons, entre le champ de Mars et la rode. Proche de l’université, des arrêts de bus principaux et de la piste cyclable, l’accès y est grandement facilité.
              <br/>  Ce centre à l’ambiance chaleureuse et dynamique est équipé de la dernière génération du Cellu M6 : c’est l’ENDERMOLOGIE ALLIANCE de chez LPG.
              </Typography>
            </Grid>
           
            <Grid  ref={reveal14} className={maxScrollTop > getRectTop(reveal14) ? "fe-fade reveal"   : "fe-fade " } item  md={12} >
              <Typography my='1em' textAlign="center" variant='body1'>
              Violaine et Christopher, deux personnalités dynamiques maitrisant parfaitement la Mécano-Stimulation LPG®, établissent votre programme bien être et anti-âge sur mesure. 
              <br/> 
              Christopher FERREIRA et son équipe, sauront répondre à vos attentes tout au long de votre cure afin d’atteindre ensemble vos objectifs : Santé / Bien être / Beauté / Minceur.
              </Typography>
            </Grid>
           
          </Grid>
        </div>
      </section>
      <section>
      <div className=' fe-container-fluid'  >
    
     
       <Grid container spacing={3} my={6} justifyContent="center" >
              {comments.map((item, i) =>
               <Grid key={i} item xs={12} sm={6} md={4} >
               <Card elevation={6}>
             <CardHeader
               avatar={
                 <Avatar sx={{ bgcolor: item.color }} aria-label="recipe">
                   {item.name.substring(0,1)}
                 </Avatar>
               }
               title={item.name}
               subheader={item.date}
             />
             
             <CardContent>
             <Rating name="read-only" value={5} readOnly />
               <Typography variant="body2" color="text.secondary" className="display-linebreak">
               {item.comment1}
                
               
              
               { !item.expanded && item.comment2 &&
                 <>
                 ... <Link style={{cursor:'pointer'}} onClick={()=>handleChangeExpanded(i)}>Plus</Link>
                 </>
               } 
               { item.expanded &&
                 <span>
                 {item.comment2}
                 </span>
               } 
       
               
               
               </Typography>
             </CardContent>
             <CardHeader
               avatar={
                 <GoogleIcon color="primary"></GoogleIcon>
               }
               title={<Typography fontSize={12} variant="caption">Poster sur <Link fontSize={12} href="#" onClick={()=>{openLink("https://www.google.com/search?q=Violaine+Granier+Renata+Fran%C3%A7a+LPG&oq=v&gs_lcrp=EgZjaHJvbWUqBggBEEUYOzIGCAAQRRg5MgYIARBFGDsyBggCEEUYPTIGCAMQRRg9MgYIBBBFGD0yBggFEEUYQTIGCAYQRRhBMgYIBxBFGDzSAQg1ODM4ajBqN6gCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x12c91bc947b10c69:0x1db5c57daf2c3838,1,,,,")}}>Google</Link></Typography>}
              
             />
            
           </Card>
           </Grid>
          )}
          </Grid>

             


         </div>
      </section>
    </Box>
  );
}
