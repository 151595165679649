import "./Glossaire.scss";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import {useEffect, useState,useRef} from 'react';
import Divider from '@mui/material/Divider';

export default function Glossaire() {
  var scrollTop = 0;
  const [maxScrollTop, setMaxScrollTop] = useState(101);

  const reveal1 = useRef(null);
  const reveal2 = useRef(null);
  useEffect(() => {
    const handleScroll = (event:any) => {
      scrollTop = Math.max(window.scrollY,scrollTop);
      setMaxScrollTop(scrollTop);
    };
    window.addEventListener('scroll', handleScroll);
    setMaxScrollTop(100);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getRectTop = (ref:any)=>{
    return ref?.current?.getBoundingClientRect().top  + window.scrollY -  document.documentElement.clientHeight + 100 };

  const  openLink =(link:string)=>{
    window.open(link,'_blank');
  }
  return ( 
    <div className="fe-container-padtop fe-container-top-bg">
    <section  className="fe-container-fluid fe-section">
    <Grid container spacing={2} ref={reveal1}>
        <Grid  className={(maxScrollTop > getRectTop(reveal1))?"fe-l-slide reveal":"fe-l-slide " }  item  xs={12}>
        
<Typography variant='h4' color="#98857E" >A </Typography>
<Divider  sx={{width:'100%'}} ></Divider>
<Typography variant='h6' color="#98857E" >Adipocyte </Typography> 
<Typography variant='body1' color="#98857E" >Cellule responsable du stockage des graisses (lipogenèse) et de leur libération (lipolyse).  </Typography> 
<Divider sx={{width:'100%'}} ></Divider>

<Typography variant='h4' mt={4} color="#98857E" >C </Typography>
<Divider  sx={{width:'100%'}} ></Divider>
<Typography variant='h6' color="#98857E" >Cellulite </Typography> 
<Typography variant='body1' color="#98857E" >Affection typiquement féminine caractérisée par un aspect « peau d’orange » de la surface cutanée, d’intensité plus ou moins sévère, et localisée principalement dans les zones glutéo-fémorales (fesses et cuisses), résistante aux régimes hypo-caloriques et à l’exercice physique. 
Aspect peau d’orange dû à un stockage accru de graisse dans l’hypoderme féminin associé à la compression et à la rétraction de ses cloisons (septa).  </Typography> 
<Divider sx={{width:'100%'}} ></Divider>
<Typography variant='h6' color="#98857E" >Collagénases </Typography> 
<Typography variant='body1' color="#98857E" >Enzymes gloutonnes qui dégradent le collagène, responsable de la tonicité de la peau. Ces enzymes sont de plus en plus présentes au cours du vieillissement de la peau et pratiquement inexistantes dans les peaux jeunes ou protégées du soleil. Ces enzymes induisent donc une dégradation du tissu conjonctif cutané. L’étude du Pr HUMBERT a montré que l’endermologie a une action sur certaines de ces collagénases en induisant un remodelage bénéfique au niveau du derme.   </Typography> 
<Divider sx={{width:'100%'}} ></Divider>

<Typography variant='h4' mt={4} color="#98857E" >D </Typography>
<Divider  sx={{width:'100%'}} ></Divider>
<Typography variant='h6' color="#98857E" >Derme </Typography> 
<Typography variant='body1' color="#98857E" >Le derme est une des trois couches constitutives de la peau comprise entre l’épiderme et l’hypoderme, principalement composé de collagène, de fibres élastiques, d’acide hyaluronique et de fibroblastes et qui confère à la peau son élasticité et sa tonicité. 
  </Typography> 
<Divider sx={{width:'100%'}} ></Divider>
 
<Typography variant='h4' mt={4} color="#98857E" >E </Typography>
<Divider  sx={{width:'100%'}} ></Divider>
<Typography variant='h6' color="#98857E" >Emiline </Typography> 
<Typography variant='body1' color="#98857E" >Elastinmicrofibril interfase-located protein (EMILIN) est une glycoprotéine abondamment présente dans les tissus riches en élastine tels que la peau. L’élastine est un des deux constituants des fibres élastiques, le deuxième constituant étant les microfibrilles. Les fibres élastiques sont responsables des propriétés élastiques de la peau. L’EMILINE serait impliquée dans la cohésion des 2 constituants des fibres élastiques : l’élastine et les microfibrilles. L’étude de Max LAFONTAN a montré que l’émiline augmentait de 160% après 12 séances. 
Pour comprendre : voir ci dessous le schéma d’une fibre élastique composée d’élastine (en bleu) et de microfibrilles (en violet). L’émiline, non représentée, est localisée à l’interface entre microfibrilles et elastine (d’où son nom). 
  </Typography> 
<Divider sx={{width:'100%'}} ></Divider>

 
<Typography variant='h6' color="#98857E" >Endermologie </Typography> 
<Typography variant='body1' color="#98857E" >endermologie®, la technique de référence 100% naturelle, non invasive et non agressive de stimulation mécanique de la peau, permettant de réactiver le mécanisme des cellules. 
  </Typography> 
<Divider sx={{width:'100%'}} ></Divider>

<Typography variant='h6' color="#98857E" >Epiderme </Typography> 
<Typography variant='body1' color="#98857E" >L’épiderme est une des trois couches constitutives de la peau, la plus superficielle, principalement composé de kératinocytes (90 à 95%), qui a une fonction de barrière avec l’extérieur et qui s’affine avec l’âge. Avec endermologie, l’épiderme s’épaissit retrouvant ainsi une structure plus forte et plus jeune. 
  </Typography> 
<Divider sx={{width:'100%'}} ></Divider>

 
<Typography variant='h4' mt={4} color="#98857E" >F </Typography>
<Divider  sx={{width:'100%'}} ></Divider>
<Typography variant='h6' color="#98857E" >Fibroblaste de fond de ride  </Typography> 
<Typography variant='body1' color="#98857E" >Fibroblaste situé au niveau de la ride. Le Pr HUMBERT a démontré que ces fibroblastes ont une activité clairement diminuée en terme de production de collagène et de capacité contractile. L’atteinte fonctionnelle des fibroblastes constitue l’anomalie la plus importante du vieillissement cutané. Les fibroblastes se raréfient, deviennent quiescents (« se reposent ») et perdent leur activité contractile. La peau perd de son élasticité et de sa tonicité et les rides se forment. Avec endermologie, on peut « réveiller » ces fibroblastes et lutter contre la formation des rides. 
  </Typography> 
<Divider sx={{width:'100%'}} ></Divider>
 

<Typography variant='h4' mt={4} color="#98857E" >H </Typography>
<Divider  sx={{width:'100%'}} ></Divider>
<Typography variant='h6' color="#98857E" >Hypoderme  </Typography> 
<Typography variant='body1' color="#98857E" >L’hypoderme est une des trois couches constitutives de la peau comprise sous le derme et principalement composé de cellules graisseuses. C’est une zone de stockage et de réserve d’énergie. C’est une couche superficielle de graisse où siège la cellulite. Avec endermologie, la libération des graisses de l’hypoderme est favorisée, la cellulite est lissée. 
  </Typography> 
<Divider sx={{width:'100%'}} ></Divider>

<Typography variant='h4' mt={4} color="#98857E" >J </Typography>
<Divider  sx={{width:'100%'}} ></Divider>
<Typography variant='h6' color="#98857E" >Jonction dermo-épidermique   </Typography> 
<Typography variant='body1' color="#98857E" >Jonction entre le derme et l’épiderme qui permet des échanges entre les deux structures. Dans une peau jeune, cette jonction est « ondulée » ; elle se linéarise avec l’âge, ce qui diminue les échanges. Avec endermologie, les ondulations de la jonctions dermo-épidermique se recréent, les échanges entres derme et épiderme s’améliorent, la structure de la peau est comme rajeunie. 
  </Typography> 
<Divider  sx={{width:'100%'}} ></Divider>
<Typography variant='h4' mt={4} color="#98857E" >R  </Typography>
<Divider sx={{width:'100%'}} ></Divider>
<Typography variant='h6' color="#98857E" >Récepteur Béta   </Typography> 
<Typography variant='body1' color="#98857E" >Récepteur localisé à la surface de l’adipocyte et qui permet de déclencher la lipolyse (déstockage naturel des graisses). Son homologue, le récepteur alpha, permet de déclencher la lipogenèse (stockage des graisses). Ces récepteurs se répartissent différemment en fonction de la localisation du tissu adipeux. Dans la graisse intra abdominal, il y a autant de récepteurs alpha que de récepteurs beta. Par contre dans la graisse gluteo fémorale (fesses et cuisses), il y a presque 10 fois plus de récepteurs alpha que de récepteurs beta. En plus, dans cette graisse, les récepteurs beta sont très « paresseux ». C’est pourquoi il est très difficile de déloger les graisses dans ces zones. L’étude du Pr Lafontan a démontré que l’endermologie avait une action sur ce récepteur beta en le rendant moins paresseux (lipolyse +70%). 
  </Typography> 



 


        </Grid>
      </Grid>
      
      </section>
      </div>


  );
}
