import "./Pressotherapie.scss";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {useEffect, useState,useRef} from 'react';

export default function Pressotherapie() {

  var scrollTop = 0;
  const [maxScrollTop, setMaxScrollTop] = useState(101);

  const reveal1 = useRef(null);
  const reveal2 = useRef(null);
  useEffect(() => {
    const handleScroll = (event:any) => {
      scrollTop = Math.max(window.scrollY,scrollTop);
      setMaxScrollTop(scrollTop);
    };
    window.addEventListener('scroll', handleScroll);
    setMaxScrollTop(100);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getRectTop = (ref:any)=>{
    return ref?.current?.getBoundingClientRect().top  + window.scrollY -  document.documentElement.clientHeight + 100 };

  const  openLink =(link:string)=>{
    window.open(link,'_blank');
  }
  return ( 
    
    <div className="fe-container-padtop fe-container-top-bg">
    <section  className="fe-container-fluid fe-section">
    <Grid container spacing={2} ref={reveal1}>
        <Grid  className={(maxScrollTop > getRectTop(reveal1))?"fe-l-slide reveal":"fe-l-slide " }  item md={8} xs={12} sx={{display:'inline-block',pr:{md:'100px',xs:'0px'}}}>
          <Box sx={{p:{md:'0px',xs:'20px'}}}>
          <Typography sx={{fontStyle:'italic',mb:'1em'}} variant="h4" color="#98857E" >
          <Box sx={{mr:'10px',float:'left'}}>
            <img  src='/media/comma.png'></img>
          </Box>
          Pressothérapie 
          </Typography>
          <Box sx={{pl:'36px'}}>
          <Typography  variant="subtitle1" paragraph >La pressothérapie est une technique de stimulation de la circulation veino-lymphatique par pressions mécaniques. A la base, la pressothérapie est Utilisée à des fins médicales pour le traitement des problèmes circulatoires.</Typography>
          <Typography  variant="subtitle1"  paragraph>Elle s’est ensuite diversifiée en devenant une  technique de choix dans le domaine de l'esthétique : on parle aussi de soins presso - esthétiques. </Typography>
          <Typography  variant="subtitle1" paragraph >Complément efficace au drainage lymphatique manuel, la pressothérapie permet d'améliorer la texture de la peau, de réduire l'aspect de la cellulite et de raffermir les tissus. </Typography>
          <Typography  variant="subtitle1"  paragraph>Elle donne au passage un effet apaisant offrant sensation de légèreté et bien être.</Typography>
          </Box>
         
          </Box>
        </Grid>
        <Grid item md={4} xs={12} sx={{textAlign:'center',m:{md:'0px',xs:'3em'}}}>
          <div    className={(maxScrollTop > getRectTop(reveal1))?"fe-r-slide reveal":"fe-r-slide " } >
            <div className="fe-photo-back">
              <img  style={{ maxHeight: "400px" }} src='/media/pressotherapie4.jpg' ></img>
            </div>
          </div>
        </Grid>
      </Grid>
      
      </section>
      </div>
   

  );
}
