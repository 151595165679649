import { Link, Outlet } from 'react-router-dom';
import Header from './components/Header';
import './MainLayout.scss';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Footer from './components/Footer';
import Box from '@mui/material/Box';





const theme = createTheme({
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 16,
    fontFamily: 'MontserratRegular'
      
  },
  palette: {
    background: {
      paper: "#fff",
    },
    text: {
      primary: "#1A1615",
      secondary: "#98857E",
     
    },
    action: {
      active: "#675436",
    },
    primary: {
      main: "#BCA47E",
    },
    info: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#ddd",
    },
  },
});
// marginTop:{xs:"160px",sm:"160px",md:"80px",xl:"0"}
function MainLayout() {

  

 
 
 
  return (
    <div className='fe-mainlayout'>
    <ThemeProvider theme={theme}>
    <Header></Header>
   
    <div className='fe-container '>
      <Box sx={{marginTop:{xs:"120px",sm:"120px",md:"40px",xl:"0"}}}>

     
      <Outlet />
      </Box>
    </div>

    <Footer></Footer>
    
    
      </ThemeProvider>
  </div>
  );
}

export default MainLayout;
