import "./Tarif.scss";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {useEffect, useRef, useState} from 'react';
import Divider from '@mui/material/Divider';
import Button from "@mui/material/Button";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export default function Tarif() {
  const reveal1 = useRef(null);
  const reveal2 = useRef(null);
  const reveal3 = useRef(null);
  
  var scrollTop = 0;
  const [maxScrollTop, setMaxScrollTop] = useState(101);

  useEffect(() => {
    const handleScroll = (event:any) => {
      scrollTop = Math.max(window.scrollY,scrollTop);
      setMaxScrollTop(scrollTop);
    };
    window.addEventListener('scroll', handleScroll);
    setMaxScrollTop(100);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getRectTop = (ref:any)=>{
    return ref?.current?.getBoundingClientRect().top  + window.scrollY -  document.documentElement.clientHeight + 100 };

  const  openLink =(link:string)=>{
    window.open(link,'_blank');
  }
 
  return ( 
    
    <div className="fe-container-padtop fe-container-top-bg">
      <section  className="fe-container-fluid fe-section">
        <Grid container spacing={3}  >
        <Grid item md={12} xs={12} sx={{display:'inline-block',pr:{md:'100px',xs:'0px'}}}>
          <Box sx={{p:{md:'0px',xs:'20px'}}}>
          <Typography  ref={reveal1} className={(maxScrollTop > getRectTop(reveal1))?"fe-r-slide reveal":"fe-r-slide " } sx={{fontStyle:'italic',mb:'1.5em'}} variant="h4" color="#98857E" >
          <Box sx={{mr:'10px',float:'left'}}>
            <img  src='/media/comma.png'></img>
          </Box>
            Nos Tarifs
          </Typography>
          </Box>
        </Grid>
        <Grid ref={reveal2} className={   maxScrollTop > getRectTop(reveal2) ? "fe-fade reveal": "fe-fade " } sx={{mb:'1em'}} item  xs={12} sm={12} lg={6}>
            <div className="fe-title-tarif">
            <Typography  variant="h6" color="#98857E" >
              LPG
            </Typography>
            </div>
              <div className="fe-box-tarif">
               <div className="fe-box-tarif-image "><img src="/media/lpg_img_2.jpg"/></div>
               <div className="fe-box-tarif-text" > 
              < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography  variant="h6">
                    JEUNE MAMAN - 30 MIN
                    </Typography>
                    
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      50,00€
                    </Typography>
                  </Grid>
                </Grid>
                <Typography  variant="subtitle1"  color="#000000cc" >
                    Cible les zones « critiques » (ventre, taille,
                    fesses, cuisses) pour retrouver une silhouette
                    harmonieuse après l’arrivée de bébé tout en
                    apportant une véritable sensation de bien-être.
                   
                    </Typography>

                < Grid container spacing={2} display="flex" mt={2} sx={{borderTop:'1px solid #ffffff77'}}>
                  <Grid item flex={1} >
                    <Typography  variant="h6">
                    ANTI-CELLULITE - 40 MIN
                    </Typography>
                   
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      65,00€
                    </Typography>
                  </Grid>
                </Grid>
                <Typography  variant="subtitle1"  color="#000000cc" >
                    Déstocke les graisses localisées, relance les
                    échanges circulatoires et raffermit pour retrouver
                    une peau plus lisse, plus ferme et un corps plus
                    léger. Ce soin agit sur tous les types de cellulite
                    (adipeuse, aqueuse et fi breuse).
                </Typography>

                < Grid container spacing={2} display="flex" mt={2} sx={{borderTop:'1px solid #ffffff77'}}>
                  <Grid item flex={1} >
                    <Typography  variant="h6">
                    SOINS CLASSIQUES VISAGE
                    </Typography>
                  </Grid>
                </Grid>
                < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                    ÉCLAT - 15 MIN
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      30,00€
                    </Typography>
                  </Grid>
                </Grid>
                < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                    DETOX - 20 MIN
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      35,00€
                    </Typography>
                  </Grid>
                </Grid>
                < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                    DÉCOLLETÉ ET BUSTE - 25 MIN
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      45,00€
                    </Typography>
                  </Grid>
                </Grid>
                < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                    ANTI-ÂGE REPULPANT - 30 MIN
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      50,00€
                    </Typography>
                  </Grid>
                </Grid>
                < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                    ANTI-ÂGE FERMETÉ - 30 MIN
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      50,00€
                    </Typography>
                  </Grid>
                </Grid>
                < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                    ANTI-ÂGE AFFINANT - 30 MIN
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      50,00€
                    </Typography>
                  </Grid>
                </Grid>



                < Grid container spacing={2} display="flex" mt={2} sx={{borderTop:'1px solid #ffffff77'}}>
                  <Grid item flex={1} >
                    <Typography  variant="h6">
                    BILAN PERSONNALISÉ 
                    </Typography>
                    
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      30,00€
                    </Typography>
                  </Grid>
                </Grid>
                < Grid container spacing={2} display="flex" mt={2} sx={{borderTop:'1px solid #ffffff77'}}>
                  <Grid item flex={1} >
                    <Typography  variant="h6">
                    KIT ENDERMOLOGIE ® VISAGE
                    </Typography>
                    
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      25,00€
                    </Typography>
                  </Grid>
                </Grid>

                < Grid container spacing={2} display="flex" mt={2} sx={{borderTop:'1px solid #ffffff77'}}>
                  <Grid item flex={1} >
                    <Typography  variant="h6">
                    FORFAIT BOOSTER <small><i>(CORPS OU VISAGE)</i></small>
                    </Typography>
                    <Typography  variant="subtitle1"  color="#000000cc" >
                    2 soins endermologie® corps par semaine pendant 5 semaines.
                    </Typography>

                    <Typography  variant="h6">
                    11 SÉANCES :
                    </Typography>
                  </Grid>
                </Grid>

                
                < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                   20 MIN
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      350,00€
                    </Typography>
                  </Grid>
                </Grid>
                < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                   30 MIN
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      500,00€
                    </Typography>
                  </Grid>
                </Grid>
                < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                   40 MIN
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      650,00€
                    </Typography>
                  </Grid>
                </Grid>
                < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography sx={{fontStyle:"italic"}} variant="h5">
                    OFFERTS  :
                    </Typography>
                    <Typography sx={{fontStyle:"italic"}} variant="body1">
                    </Typography>
                    <Typography sx={{fontStyle:"italic"}} variant="subtitle1"  color="#000000cc" >
                    <span style={{fontWeight:'bold'}}>1 Séance Offerte</span>
                    <br/>1 Bilan personnalisé initial
                    <br/>1 Tenue endermowear ™ Collant ou clapet
                    
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    
                  </Grid>
                </Grid>

                < Grid container spacing={2} display="flex" mt={2} sx={{borderTop:'1px solid #ffffff77'}}>
                  <Grid item flex={1} >
                    <Typography  variant="h6">
                    FORFAIT OPTIMISATION <small><i>(CORPS OU VISAGE)</i></small>
                    </Typography>
                    <Typography  variant="subtitle1"  color="#000000cc" >
                    Ce programme permet de maintenir et d’améliorer les résultats obtenus.
                    </Typography>
                    <Typography  variant="h6">
                    11 SÉANCES :
                    </Typography>
                  </Grid>
                </Grid>

                < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                   20 MIN
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      350,00€
                    </Typography>
                  </Grid>
                </Grid>
                < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                   30 MIN
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      500,00€
                    </Typography>
                  </Grid>
                </Grid>
                < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                   40 MIN
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      650,00€
                    </Typography>
                  </Grid>
                </Grid>
                < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography sx={{fontStyle:"italic"}} variant="h5">
                    OFFERTS  :
                    </Typography>
                   
                   
                    <Typography sx={{fontStyle:"italic"}} variant="subtitle1"  color="#000000cc" >
                    <span style={{fontWeight:'bold'}}>1 Séance Offerte</span>
                    <br/>1 Bilan personnalisé initial
                    <br/>1 Tenue endermowear ™ Collant ou clapet
                    </Typography>
                    
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                  
                  </Grid>
                </Grid>
                


                < Grid container spacing={2} display="flex" mt={2} sx={{borderTop:'1px solid #ffffff77'}}>
                  <Grid item flex={1} >
                    <Typography  variant="h6">
                    FORFAIT 24 SÉANCES <small><i>(CORPS OU VISAGE)</i></small>
                    </Typography>
                    <Typography  variant="subtitle1"  color="#000000cc" >
                    VOTRE PRISE EN CHARGE RÉUSSITE
(BOOSTER + OPTIMISATION).
                    </Typography>

                    <Typography  variant="h6">
                    À PARTIR DE 24 SÉANCES:
                    </Typography>
                  </Grid>
                </Grid>

                
                < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                   20 MIN
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      750,00€
                    </Typography>
                  </Grid>
                </Grid>
                < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                   30 MIN
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      1000,00€
                    </Typography>
                  </Grid>
                </Grid>
                < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                   40 MIN
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      1250,00€
                    </Typography>
                  </Grid>
                </Grid>
                < Grid container spacing={2} display="flex" >
                  <Grid item flex={1} >
                    <Typography sx={{fontStyle:"italic"}} variant="h5">
                    OFFERTS  :
                    </Typography>
                    <Typography sx={{fontStyle:"italic"}} variant="body1">
                    (VALEUR TOTALE)
                    </Typography>
                    <Typography sx={{fontStyle:"italic"}} variant="subtitle1"  color="#000000cc" >
                    4 séances endermologie®
                    <br/>1 Bilan personnalisé initial - 30 min
                    <br/>1 Bilan personnalisé intermédiaire - 30 min
                    </Typography>
                    
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right',fontStyle:"italic",textDecoration:'underline'}} variant="h5">
                      200,00€
                    </Typography>
                  </Grid>
                </Grid>
                </div>
              </div>
            </Grid>
          <Grid ref={reveal3} className={   maxScrollTop > getRectTop(reveal3) ? "fe-fade reveal": "fe-fade " } sx={{mb:'1em'}} item  xs={12} sm={12} lg={6}>
            <div className="fe-title-tarif">
            <Typography  variant="h6" color="#98857E" >
              RENATA FRANÇA
            </Typography>
            </div>
              <div className="fe-box-tarif">
               <div className="fe-box-tarif-image "><img src="/media/franca3.jpg"/></div>
               <div className="fe-box-tarif-text" > 
                <Grid container spacing={2}  >
                  <Grid item xs>
                    <Typography  variant="h6">
                      DRAINAGE LYMPHATIQUE 
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      130€
                    </Typography>
                  </Grid>
                </Grid>
                <Typography  variant="subtitle1" color="#000000cc" >
                    Durée:  ± 1H15
                </Typography>
                <Grid container spacing={2}  >
                    <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                    CURE X 5 
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                    <small style={{textDecoration:'line-through',color:"#000000cc"}}  >650€</small> 550€ 
                    </Typography>
                  </Grid>
                </Grid>
              

            <Grid container spacing={2}  mt={2} sx={{borderTop:'1px solid #ffffff77'}}>
                  <Grid item xs>
                    <Typography  variant="h6">
                    REMODELAGE
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      150€
                    </Typography>
                  </Grid>
                </Grid>
                <Typography  variant="subtitle1" color="#000000cc" >
                    Durée:  ± 1H15
                </Typography>
                <Grid container spacing={2}  >
                    <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                    SI EN + D’UNE CURE DE 5 
                    <br/>DRAINAGES LYMPHATIQUE
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                    <br/> <small style={{textDecoration:'line-through',color:"#000000cc"}}  >150€</small> 120€ 
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}  mt={2} sx={{borderTop:'1px solid #ffffff77'}}>
                  <Grid item xs>
                    <Typography  variant="h6">
                    MIRACLE FACE
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                      60€
                    </Typography>
                  </Grid>
                </Grid>
                <Typography  variant="subtitle1" color="#000000cc" >
                    Durée:  30 MIN
                </Typography>
                <Grid container spacing={2}  >
                    <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                    CURE X 5 
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                    <small style={{textDecoration:'line-through',color:"#000000cc"}}  >300€</small> 250€ 
                    </Typography>
                  </Grid>
                </Grid>
                </div>
              </div>


            <div className="fe-title-tarif" style={{marginTop:'3em'}}>
            <Typography  variant="h6" color="#98857E" >
            PRESSOTHERAPIE
            </Typography>
            </div>
              <div className="fe-box-tarif">
               <div className="fe-box-tarif-image "><img src="/media/pressotherapie2.jpg"/></div>
               <div className="fe-box-tarif-text" > 
                <Grid container spacing={2}  >
                  <Grid item xs>
                    <Typography  variant="h6">
                    30 MIN      
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                    30€          
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}  >
                  <Grid item xs>
                    <Typography  variant="h6">
                    40  MIN      
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                    40€          
                    </Typography>
                  </Grid>
                </Grid>
               
                <Grid container spacing={2}  >
                    <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                    CURE X 5 (30 MIN)
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                    <small style={{textDecoration:'line-through',color:"#000000cc"}}  >150€</small> 120€ 
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}  >
                    <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                    CURE X 5 (40 MIN)
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                    <small style={{textDecoration:'line-through',color:"#000000cc"}}  >200€</small> 170€ 
                    </Typography>
                  </Grid>
                </Grid>
                </div>
              </div>

            <div className="fe-title-tarif" style={{marginTop:'3em'}}>
            <Typography  variant="h6" color="#98857E" >
            COACHING SPORTIF PERSONNALISE
            </Typography>
            <Typography  variant="subtitle1" color="#98857E" >
            INDIVIDUEL OU COLLECTIF
            </Typography>
            </div>
              <div className="fe-box-tarif">
               <div className="fe-box-tarif-image "><img src="/media/sport.jpg"/></div>
               <div className="fe-box-tarif-text" > 
                <Grid container spacing={2}  >
                  <Grid item xs>
                    <Typography  variant="h6">
                    45 MIN      
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                    30€          
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}  >
                  <Grid item xs>
                    <Typography  variant="h6">
                    60  MIN      
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                    45€          
                    </Typography>
                  </Grid>
                </Grid>
               
                <Grid container spacing={2}  >
                    <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                    FORFAIT DE PLUSIEURS SEANCES SEANCES
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                    
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}  >
                    <Grid item flex={1} >
                    <Typography  variant="subtitle1">
                    COURS COLLECTIF
                    </Typography>
                  </Grid>
                  <Grid item sx={{flexShrink:0}}>
                    <Typography sx={{textAlign:'right'}} variant="h6">
                    
                    </Typography>
                  </Grid>
                </Grid>
                </div>
              </div>


            </Grid>

        </Grid>
    </section>
   </div>

  );
}
