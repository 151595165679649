import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Campaign from '@mui/icons-material/Campaign';
import Comment from '@mui/icons-material/Comment';
import authContext from '../../authentication/auth.context';
import React, { useContext } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { Divider } from '@mui/material';
import { Link  as RouterLink} from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import UserService from "../../authentication/user.service";
const userService = new UserService();

export default function AdminMenu() {
   
      const navigate = useNavigate();
    const {authenticated, setAuthenticated} = useContext(authContext)
    const handleLogout =  (e:any) => {
      e.preventDefault();
  
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        if(refreshToken){
          userService.logout(refreshToken).then(()=>{
            setAuthenticated(false);
            navigate("/Administration/LoginAdmin");
          }).catch((error) =>{
            // Handle login error
            setAuthenticated(false);
          });
        }
      
      
      } catch (error) {
        // Handle login error
        setAuthenticated(false);
      }
    };
  return ( 
    <React.Fragment>
       
    {authenticated && 
     <div className='fe-admin-menu '>
        <RouterLink to="/">
            <img style={{ maxHeight: "50px" ,margin:"10px",marginBottom:"0"}} src='/media/mainlogo.png' ></img>
             </RouterLink>
    <MenuList>
      <Divider></Divider>
        <MenuItem component={RouterLink} to="/Administration/Promo">
          <ListItemIcon>
            <Campaign fontSize="small" />
          </ListItemIcon>
          <ListItemText>Bandeau</ListItemText>
        </MenuItem>
        <MenuItem component={RouterLink} to="/Administration/Comments">
          <ListItemIcon>
            <Comment fontSize="small" />
          </ListItemIcon>
          <ListItemText>Commentaires</ListItemText>
        </MenuItem>
        <Divider></Divider>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Déconnexion</ListItemText>
        </MenuItem>
      </MenuList>
      </div>
    }
    </React.Fragment>
  );
}
