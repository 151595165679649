import axios from "axios";
import { useEffect, useContext } from "react";
import authContext from "../authentication/auth.context";
import { useNavigate } from "react-router-dom";
// axios instance
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);



const AxiosInterceptor = ({ children }:any) => {

 
 
  const {authenticated, setAuthenticated} = useContext(authContext)


  useEffect(() => {
   
    
    const resInterceptor = (response:any) => {
     
      return response;
    };

    const errInterceptor = async (error:any) => {
      console.log("errInterceptor");
      const originalRequest = error.config;
  
      // If the error status is 401 and there is no originalRequest._retry flag,
      // it means the token has expired and we need to refresh it
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
  
        try {
          const rt = localStorage.getItem('refreshToken');
          const response = await axios.post('/api/refresh-token', { rt });

          const { accessToken, refreshToken } = response.data;
          localStorage.setItem('refreshToken', refreshToken);
          localStorage.setItem('token', accessToken);

          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axios(originalRequest);
        } catch (error) {
          // Handle refresh token error or redirect to login
          localStorage.setItem('refreshToken', "");
          localStorage.setItem('token', "");
          setAuthenticated(false);
        }
      }
      else if(error.response.status === 403)
      {
        localStorage.setItem('refreshToken', "");
        localStorage.setItem('token', "");
        setAuthenticated(false);
      }

      return Promise.reject();
    };

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => instance.interceptors.response.eject(interceptor);
  }, []);

  return children;
};

export default instance;
export { AxiosInterceptor };

/*import axios from 'axios';

const api = axios.create({
  baseURL: 'http://localhost:5000/api',
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
  
      // If the error status is 401 and there is no originalRequest._retry flag,
      // it means the token has expired and we need to refresh it
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
  
        try {
          const refreshToken = localStorage.getItem('refreshToken');
          const response = await axios.post('/api/refresh-token', { refreshToken });
          const { token } = response.data;
  
          localStorage.setItem('token', token);
  
          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axios(originalRequest);
        } catch (error) {
          // Handle refresh token error or redirect to login
        }
      }
  
      return Promise.reject(error);
    }
  );
  
export default api*/