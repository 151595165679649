import { useParams } from "react-router-dom";
import "./Renata.scss";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {useEffect, useRef, useState} from 'react';
import Divider from '@mui/material/Divider';
import Button from "@mui/material/Button";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';

export default function Renata() {
  let { section } = useParams();
  
  const reveal15 = useRef(null);
  const reveal16 = useRef(null);
  const reveal17 = useRef(null);
  const reveal18 = useRef(null);
  const reveal19 = useRef(null);
  const reveal20 = useRef(null);
  const reveal21 = useRef(null);
  const reveal22 = useRef(null);
  const reveal23 = useRef(null);
  const reveal24 = useRef(null);

  var scrollTop = 0;
  const [maxScrollTop, setMaxScrollTop] = useState(101);

  useEffect(() => {
    const handleScroll = (event:any) => {
      scrollTop = Math.max(window.scrollY,scrollTop);
      setMaxScrollTop(scrollTop);
    };
    window.addEventListener('scroll', handleScroll);
    setMaxScrollTop(100);
   
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getRectTop = (ref:any)=>{
    return ref?.current?.getBoundingClientRect().top  + window.scrollY -  document.documentElement.clientHeight + 100 };

  const  openLink =(link:string)=>{
    window.open(link,'_blank');
  }
  useEffect(() => {
    goToSection(section || '');
  }, [section]);

  const goToSection = (section:string) => {
    let element = document.getElementById(section);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
    
  };
  return ( 
    <div className="fe-container-padtop">
    <Box sx={{width:'100%'}}>

         <section id="DrainageLymphatique" className=" fe-section fe-section-renata">
      <div className="fe-top-renata "></div>
      <div className="fe-container-fluid">
    <Grid container spacing={2}>
        <Grid  ref={reveal15} className={(maxScrollTop > getRectTop(reveal15))?"fe-l-slide reveal":"fe-l-slide " }  item md={8} xs={12} sx={{display:'inline-block',pr:{md:'100px',xs:'0px'}}}>
          <Box sx={{p:{md:'0px',xs:'20px'}}}>
          <Typography sx={{fontStyle:'italic',mb:'1em'}} variant="h4" color="#98857E" >
          <Box sx={{mr:'10px',float:'left'}}>
            <img  src='/media/comma.png'></img>
          </Box>
          França Renata
          </Typography>
          <Typography  variant="h5" color="#98857E" mb={2} >
          Le drainage lymphatique Renata França
          </Typography>
          <Box sx={{pl:'36px'}}>
          <Typography  variant="subtitle1"  paragraph>Le drainage lymphatique Renata França compte sur une pression ferme et un rythme accéléré, en plus de pompages et des manœuvres exclusives qui permettent des résultats immédiats et spectaculaires.  </Typography>
          <Typography  variant="subtitle1"  paragraph>Il suit méthodologiquement le trajet circulatoire de la lymphe.  </Typography>
          <Typography  variant="subtitle1"  paragraph>Essentielle au bon fonctionnement de notre système immunitaire, la lymphe transporte les déchets et les toxines accumulés dans l’organisme jusqu’aux ganglions lymphatiques situés un peu partout dans notre corps. </Typography>
          <Typography  variant="subtitle1"  paragraph>Le système lymphatique est comparable au système sanguin, à la seule différence qu’il a besoin d’être stimulé car il fonctionne au ralenti. Et pour cause, les ganglions lymphatiques ne pompent pas naturellement la lymphe, comme le cœur le fait avec le sang ». Ainsi, il est bon de donner un coup de boost à la lymphe à travers cette méthode. </Typography>

          <Typography  variant="subtitle1"  paragraph>Rappelons aussi que la circulation lymphatique est activée par l'action musculaire du corps humain et que la sédentarité de nos modes de vie moderne est souvent la cause d'un système lymphatique déficient. L'aide mécanique apportée par un drainage lymphatique méthode Renata França est indiscutablement efficace pour relancer un système lymphatique « faignant » et diminuer la cellulite aqueuse en agissant sur la répartition des fluides corporels. </Typography>
          <Typography  variant="subtitle1"  paragraph>Cette stimulation circulatoire ne s'arrête pas à la circulation lymphatique mais agit également sur l'amélioration de la circulation sanguine. </Typography>
          </Box>

          

          </Box>
        </Grid>
        <Grid item md={4} xs={12} sx={{textAlign:'center',my:'4em'}}>
          <div    className={(maxScrollTop > getRectTop(reveal15))?"fe-r-slide reveal":"fe-r-slide " } >
            <div className="fe-photo-back">
              <img style={{ maxHeight: "350px" }} src='/media/photo3.jpg' ></img>
            </div>
          </div>
        </Grid>
       
        <Grid ref={reveal16} item xs={12} >
          
          <Typography className={(maxScrollTop > getRectTop(reveal16))?"fe-l-slide reveal":"fe-l-slide "}  variant="subtitle1"  paragraph><b>Les bienfaits de cette technique : </b></Typography>
          <Grid container spacing={3} >
          <Grid item xs={12} xl={6} className={(maxScrollTop > getRectTop(reveal16))?"fe-l-slide reveal":"fe-l-slide "}>
          <List  className="fe-list">
                <ListItem> <ListItemText primary="Perte de poids"  sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Élimination de la rétention d'eau" sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Optimisation de la perte de cellulite aqueuse" sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Amélioration de la fermeté de la peau" sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Amélioration de la circulation lymphatique" sx={{textAlign:"center"}}/></ListItem>
            </List>
          </Grid>
          <Grid  item xs={12} xl={6} className={(maxScrollTop > getRectTop(reveal16))?"fe-r-slide reveal":"fe-r-slide "}>
          <List  className="fe-list">  
                <ListItem> <ListItemText primary="Amélioration de la circulation sanguine" sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Élimination des toxines qui polluent l'organisme, nettoyage, régénération" sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Aide au bon fonctionnement du système immunitaire" sx={{textAlign:"center"}}/></ListItem>
                <ListItem id="Remodelage"> <ListItemText primary="Soulagement des jambes lourdes" sx={{textAlign:"center"}}/></ListItem>
              
            </List>
          </Grid>
          </Grid>
          
        </Grid>
        
        <Grid item xs={12} >
          <Divider sx={{width:'100%'}} ><img  src='/media/comma.png'></img></Divider>
        </Grid>
        <Grid  ref={reveal18} className={(maxScrollTop > getRectTop(reveal18))?"fe-l-slide reveal":"fe-l-slide " }  item  xs={12} >
          <Typography  variant="h5" color="#98857E" mb={2} >
          Le REMODELAGE Méthode RENATA FRANCA
          </Typography>
          <Box sx={{pl:'36px'}}>
          <Typography  variant="subtitle1"  >Ce massage remodelant est caractérisée par des mouvements vigoureux, rapides et fermes sur tout le corps mais principalement sur les hanches, les cuisses et les fesses. </Typography>
          <Typography  variant="subtitle1"  >Ces mouvements malaxent également les adhérences graisseuses, permettant ainsi de déplacer les adipocytes au bons endroits, de sculpter et dessiner votre corps. </Typography>
          <Typography  variant="subtitle1"  paragraph>On parle alors de réorganisation tissulaire. </Typography>

          <Typography  variant="subtitle1"  >Le remodelage doit s’effectuer sur un corps déjà drainé, la plupart du temps après 3 séances de drainage lymphatique méthode Renata França. Mais à adapter en fonction des personnes et des besoins.</Typography>
          </Box>
        </Grid>
        
        <Grid ref={reveal19} item xs={12} mt={2}>
          <Grid container spacing={3}  >
          <Grid item xs={12} xl={6} >
            <Box className={(maxScrollTop > getRectTop(reveal19))?"fe-l-slide reveal":"fe-l-slide "}>
          <Typography  variant="subtitle1" sx={{width:'100%',textAlign:'center'}}  paragraph><b>Ce remodelage permet de :  </b></Typography>
          <List  className="fe-list">
                <ListItem> <ListItemText primary="Traiter la cellulite"  sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Raffermir la peau" sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Redessiner la silhouette. " sx={{textAlign:"center"}}/></ListItem>
                
            </List>
            </Box>
          </Grid>
          <Grid  item xs={12} xl={6} className={(maxScrollTop > getRectTop(reveal19))?"fe-r-slide reveal":"fe-r-slide "}>
          <Typography  variant="subtitle1" sx={{width:'100%',textAlign:'center'}}  paragraph><b>Les résultats obtenus :  </b></Typography>
          <List  className="fe-list">  
                <ListItem> <ListItemText primary="Formes sublimées, beaucoup plus galbées" sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Capitons moins visibles" sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Cellulite réduite" sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Peau plus lisse, reliftée" sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Processus cellulaire de cicatrisation de renouvellement accéléré." sx={{textAlign:"center"}}/></ListItem>
            </List>
          </Grid>
          </Grid>
        </Grid>

        <Grid  ref={reveal20} className={(maxScrollTop > getRectTop(reveal20))?"fe-l-slide reveal":"fe-l-slide " }  item  xs={12} mt={2}>
          <Box sx={{pl:'36px'}}>
          <Typography  variant="subtitle1"   id="Miracleface">Pour un résultat optimal, vous avez la possibilité d’alterner Drainage Lymphatique et Remodelage durant votre cure. </Typography>
          <Typography  variant="subtitle1"  >Comme pour le drainage lymphatique, les résultats sont immédiats et vont être sublimés durant les 72 heures qui suivent. Il est important d’éviter tout excès de sucre, gras et alcool avant et après le soin. Les résultats et leur maintien dépendent aussi de votre hygiène de vie, d’une alimentation saine et de la pratique de sport. Comme pour le drainage lymphatique, il est essentiel de boire minimum 1,5L d’eau par jour</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{width:'100%'}} ><img  src='/media/comma.png'></img></Divider>
        </Grid>
        <Grid  ref={reveal21} className={(maxScrollTop > getRectTop(reveal21))?"fe-l-slide reveal":"fe-l-slide " }  item  xs={12} >
          <Typography  variant="h5" color="#98857E" mb={2} >
          Le MIRACLE FACE : drainage lymphatique du visage
          </Typography>
          <Box sx={{pl:'36px'}}>
          <Typography  variant="subtitle1"  >Le MIRACLE FACE utilise les manœuvres du célèbre drainage lymphatique RENATA FRANÇA et permet d’obtenir un résultat aussi spectaculaire que la version corporelle.
          Le système lymphatique circule également dans notre visage dans le but d’évacuer les déchets et toxines. Lorsqu’ils s’accumulent, la lymphe stagne et l’inflammation se créée.
          </Typography>
          <Typography  variant="subtitle1"  paragraph>Ce drainage du visage active la circulation lymphatique, détoxifie et décongestionne ainsi votre visage. Les liquides en excès sont évacués.</Typography>
          

          <Typography  variant="subtitle1"  >Vous souhaitez dire stop au visage gonflé au réveil et aux poches disgracieuses sous les yeux ? Le miracle face sera un allié exceptionnel pour vous !</Typography>
          </Box>
        </Grid>
        <Grid ref={reveal22} item xs={12} mt={2}>
          <Grid container spacing={3}  >
          <Grid item xs={12} xl={6} className={(maxScrollTop > getRectTop(reveal22))?"fe-l-slide reveal":"fe-l-slide "}>
         
          <List  className="fe-list">
                <ListItem> <ListItemText primary="Désengorge le visage"  sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Draine les tissus, tonifie et oxygène" sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Atténue les rides dues à la rétention d’eau, le double menton, sillons, cernes, front …" sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Détoxifie la peau " sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Réoxygène les tissus (cernes creuses et foncées) " sx={{textAlign:"center"}}/></ListItem>
            </List>
          </Grid>
          <Grid  item xs={12} xl={6} className={(maxScrollTop > getRectTop(reveal22))?"fe-r-slide reveal":"fe-r-slide "}>
         
          <List  className="fe-list">  
                <ListItem> <ListItemText primary="Redessiner le visage + effet lift" sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Réactive la revitalisation naturelle de la peau" sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Donne un coup d’éclat" sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Accélère le processus cellulaire de cicatrisation et de réparation" sx={{textAlign:"center"}}/></ListItem>
                <ListItem> <ListItemText primary="Agit sur les toxines et inflammations : atténue les migraines" sx={{textAlign:"center"}}/></ListItem>
            </List>
          </Grid>
          </Grid>
        </Grid>

        <Grid  ref={reveal23} className={(maxScrollTop > getRectTop(reveal23))?"fe-l-slide reveal":"fe-l-slide " }  item  xs={12} mt={2}>
          <Box sx={{pl:'36px'}}>
          <Typography  variant="subtitle1"  >Comme pour tout drainage, il est important de boire minimum 1,5L d’eau pure par jour. </Typography>
          <Typography  variant="subtitle1"  >Il est important de faire le soin Miracle Face régulièrement pour obtenir durablement les effets du soin.</Typography>
          <Typography  variant="subtitle1"  >La méthode Renata França fait aussi parler d’elle sur la toile</Typography>
          <Link href="#" onClick={()=>{openLink('https://www.elle.fr/Beaute/Soins/Tendances/Methode-Renata-Franca-un-drainage-lymphatique-aux-multiples-vertus-4131329')}}> Méthode Renata França : un drainage lymphatique aux multiples vertus - Elle</Link> 
          <br/><Link href="#" onClick={()=>{openLink('https://lipoedeme-france.com/le-drainage-selon-renata-franca/')}}> Le drainage selon Renata França - lipœdème france</Link> 
          </Box>
        </Grid>

       

        <Grid item xs={12}>
          <Divider sx={{width:'100%',mt:4}} ><img  src='/media/comma.png'></img></Divider>
        </Grid>
        <Grid item xs={12} sx={{textAlign:'center',marginBottom:"3em"}} ref={reveal24}>
        <Button onClick={()=>openLink("https://www.doctolib.fr/masseur-kinesitherapeute/toulon/christopher-ferreira/booking?utm_campaign=website-button&amp;utm_source=christopher-ferreira-website-button&amp;utm_medium=referral&amp;utm_content=withoutpreview-blue-floating-bottom-right&amp;utm_term=christopher-ferreira")}  className={(maxScrollTop > getRectTop(reveal24))?"fe-fade reveal":"fe-fade " }  color="primary" variant="contained" size="large" sx={{mt:2,color:'#fff',flexDirection:'column'}}   >
         Decouvrez la méthode Renata França,<br></br> prenez rendez-vous sur  <div><img style={{height:'22px',verticalAlign:'middle',width:'auto'}} src='https://pro.doctolib.fr/external_button/doctolib-white-transparent.png' alt='Doctolib'/></div> 
       </Button>
        </Grid>
      </Grid>
      
      </div>
     
    </section>
    </Box>
    </div>

  );
}
