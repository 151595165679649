import "./Info.scss";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import {useEffect, useState,useRef} from 'react';
import Paper from "@mui/material/Paper";
export default function Info() {
  var scrollTop = 0;
  const [maxScrollTop, setMaxScrollTop] = useState(101);

  const reveal1 = useRef(null);

  useEffect(() => {
    const handleScroll = (event:any) => {
      scrollTop = Math.max(window.scrollY,scrollTop);
      setMaxScrollTop(scrollTop);
    };
    window.addEventListener('scroll', handleScroll);
    setMaxScrollTop(100);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getRectTop = (ref:any)=>{
    return ref?.current?.getBoundingClientRect().top  + window.scrollY -  document.documentElement.clientHeight + 100 };

  const  openLink =(link:string)=>{
    window.open(link,'_blank');
  }
  return ( 
    <div className="fe-container-padtop">
    <section ref={reveal1}  className="fe-container-fluid fe-section">
  <Grid container spacing={3}>
    <Grid  className={(maxScrollTop > getRectTop(reveal1))?"fe-fade reveal":"fe-fade " }  item xs={12} display="flex" sx={{justifyContent:"center",height:'300px',zIndex:1000}} >
    <div className='fe-photo-about'>
     <div></div>
    </div>
    </Grid>
    <Grid  className={(maxScrollTop > getRectTop(reveal1))?"fe-l-slide reveal":"fe-l-slide " } sx={{mt:{xs:'1em',sm:'1em',md:'1em',lg:'-120px'}}}  item md={6} xs={12} >
    <div  className="fe-about-title">
    <Typography variant='h5'>
        VIOLAINE GRANIER
      </Typography>
    </div>
    <div  className="fe-about-body">
     
      <Typography variant='subtitle1'>
        Infirmière et praticienne Renata França, cette passionnée a toujours eu à cœur de prendre soin des autres, avec douceur et bienveillance.
        <br/><br/>
        Formée aussi en hypnose, hypnose périnatale, experte en endermologie, cette maman d’un petit garçon de 5 ans a plus d’une corde à son arc ! <br/><br/>
        Sa devise : « le bien être est le carburant d’une vie épanouie »  Yann Feliz
      </Typography>
     </div>
    </Grid>
    <Grid className={(maxScrollTop > getRectTop(reveal1))?"fe-r-slide reveal":"fe-r-slide " } sx={{mt:{xs:'1em',sm:'1em',md:'1em',lg:'-120px'}}}  item md={6} xs={12}>
    <div  className="fe-about-title inverse">
    <Typography variant='h5' sx={{textAlign:'right'}}>
    CHRISTOPHER FERREIRA
      </Typography>
    </div>
    <div  className="fe-about-body">
     
      <Typography variant='subtitle1'>
      Passionné par le sport et la rééducation, ce kiné sérieux a plus de 10 ans d’expérience dans son domaine.  <br/><br/>
Formé en tant qu’expert en endermologie, Christopher prend son boulot à cœur avec comme objectif : le travail bien fait.  <br/><br/>
Sa devise : « Se réunir est un début, rester ensemble est un progrès, travailler ensemble est la réussite. » - Henry Ford

      </Typography>
     </div>
    </Grid>
  
   <Grid item xs={12} mt={2}>
      <Typography sx={{fontStyle:'italic',mb:'1em'}} variant="h5" color="#98857E" >
          <Box sx={{mr:'10px',float:'left'}}>
            <img  src='/media/comma.png'></img>
          </Box>
          Les professionnels de santé :
      </Typography>
    </Grid>
   <Grid item md={12}  lg={12} xl={4}>
      <Typography variant='h6'>
        Kinésithérapeute
      </Typography>
      <Typography variant='subtitle1'>
      Basée sur la rééducation fonctionnelle et manuelle, la kinésithérapie interviendra pour la remise en forme et le bien être des patients. Mais pas seulement !
      <br/>En effet, votre cabinet prendra en charge tous les bobos de la vie quotidienne, sportive et professionnelle mais également les pathologies plus chroniques.
      </Typography>
      <Typography  variant="subtitle1"  paragraph><b>Mal du dos : mal du siècle ? </b></Typography>
   
   </Grid>
   <Grid item md={12}  lg={12} xl={4}>
      <Typography variant='h6'>
      Ostéopathe
      </Typography>
      <Typography variant='subtitle1'>
      Spécialisé dans l’école du dos, c’est avec un ostéopathe présent à mi-temps au cabinet que nous tenterons de répondre au maximum à vos attentes. Cela passe par une bonne compréhension de la survenue de la douleur mais aussi par des conseils pratiques professionnels ou sportifs pour éliminer la douleur. Et enfin, par une rééducation globale afin que les maux ne reviennent plus.
      </Typography>
   </Grid>
   <Grid item md={12} lg={12} xl={4}>
      <Typography variant='h6'>
      Infirmières
      </Typography>
      <Typography variant='subtitle1'>
      A noter que 3 infirmières ont également élu domicile au cabinet, pour une prise en charge encore plus complète.
      </Typography>
   </Grid>
  </Grid>
</section>


</div>

   

  );
}
